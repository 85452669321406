import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment'
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf'
import { useReactToPrint } from 'react-to-print';
import { getPDFIncarichiDipendenteSalvati, eliminaPDF } from '../../../actions/dipendente';
import { generaFileTRRIPA, generaFileNEWTRRIPA } from '../trripa/generaFileTRRIPA'
const PDFIncarichiGPG = ({ auth: { user }, dipendente: { incarichiDipendente, incarichiDipendenteSalvati }, alert, getPDFIncarichiDipendenteSalvati, idDipendente, dataInizioInput, dataFineInput, dipendenteData, eliminaPDF }) => {

    const [csvData, setCsvData] = useState([]);
    const [indexToPrint, setIndexPrint] = useState(-1);
    const [incarichiDipendenteSalvatiArray, setIncarichiDipendenteSalvatiArray] = useState([]);


    useEffect(() => {
        setCsvData([])
        setIndexPrint(-1)
        getPDFIncarichiDipendenteSalvati({ dataInizio: dataInizioInput, dataFine: dataFineInput, idDipendente: idDipendente })
    }, [dataInizioInput, dataFineInput, idDipendente]);

    useEffect(() => {
        setIncarichiDipendenteSalvatiArray(incarichiDipendenteSalvati)
    }, [incarichiDipendenteSalvati]);


    const tableStyle = {
        width: "100%",
        border: "1px solid"
    };

    const tableStyleIndennita = {
        width: "100%",
        border: "1px solid",
        marginTop: "10px"
    };

    const tableStyleNote = {
        width: "100%",
        border: "1px solid",
        marginTop: "10px"
    };

    const tableInt = {
        fontSize: "12px",
        lineHeight: "1.3"
    };

    const tableStyle2 = {
        textAlign: "center",
        border: "1px solid",
        width: "6%",
        padding: "2px 2px",
        wordBreak: "break-all"
    }

    const tableStyle3 = {
        textAlign: "center",
        border: "1px solid",
        width: "4%",
        padding: "2px 2px"
    }
    const tableStyle4 = {
        textAlign: "center",
        border: "1px solid",
        width: "2.5%",
        padding: "2px 2px"
    }

    const tableStyle5 = {
        textAlign: "center",
        border: "1px solid",
        width: "19%",
        padding: "2px 2px"
    }

    const tableStyleIndennitaVoce = {
        textAlign: "center",
        border: "1px solid",
        width: "10%",
        padding: "2px 2px"
    }

    const tableStyleIndennitaOreGiorni = {
        textAlign: "center",
        border: "1px solid",
        width: "5%",
        padding: "2px 2px"
    }

    const tableStyleIndennitaImporto = {
        textAlign: "center",
        border: "1px solid",
        width: "15%",
        padding: "2px 2px"
    }

    const tableStyleIndennitaNote = {
        textAlign: "center",
        border: "1px solid",
        width: "60%",
        padding: "2px 2px"
    }


    const componentRef = useRef();
    const print = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: indexToPrint != -1 ? incarichiDipendenteSalvatiArray[indexToPrint].dipendente.cognome + "-" + incarichiDipendenteSalvatiArray[indexToPrint].dipendente.nome : "turni-dipendente"
    });

    const handlePrint = (i) => {
        setIndexPrint(i)
    }

    const printRef = React.useRef();

    const handleDownloadPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('print.pdf');
    };

    const fromMinutiToOreString = (minutiTotali) => {
        if (minutiTotali > 0) {
            var minuti = minutiTotali % 60;
            var ore = (minutiTotali - minuti) / 60;
            if (ore == 0 && minuti == 0) {
                return "00:00"
            } else {
                return formattaOre(ore) + ":" + formattaMinuti(minuti)
            }
        } else {
            return "00:00"
        }
    }

    const calcoloTotaliOre = (incarichi, field) => {
        let hours = 0, minutes = 0;
        incarichi.forEach((incarico) => {
            let split;
            if(field == "flessibilita"){
                let flessNum = incarico.flessibilita ? incarico.flessibilita : 0
                let fless = fromMinutiToOreString(flessNum)
                split = fless.split(':');
                hours += parseInt(split[0]);
                minutes += parseInt(split[1]);

            }else
            if (incarico[field]) {
                split = incarico[field].split(':');
                hours += parseInt(split[0]);
                minutes += parseInt(split[1]);
            }
        });
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;
        if (hours == 0 && minutes == 0) {
            return "00:00"
        } else {
            return hours + ':' + formattedNumber(minutes)
        }
    }


    const calcoloGiorniLavorati = (incarichi) => {
        let giorni = 0;
        incarichi.forEach((incarico) => {
            if (incarico.effettivo == "AS") {
                if (!incarico.multiplo || (incarico.multiplo && incarico.isLastMultiplo)) {
                    giorni = giorni + 1
                }
            }
        });
        return giorni
    }

    const calcoloTotaliOreStrOTS = (incarichi, field) => {
        let hours = 0, minutes = 0;
        incarichi.forEach((incarico) => {
            if (incarico.oreStraordinarie) {
                const split = incarico.oreStraordinarie.split(':');
                if (field == 'oreStraordinarieNotte') {
                    if (incarico.sumMinStrInt <= 2880 && incarico.isNotturna) {
                        hours += parseInt(split[0]);
                        minutes += parseInt(split[1]);
                        hours += Math.floor(minutes / 60);
                        minutes = minutes % 60;
                    }
                } else if (field == 'oreStraordinarieMin48') {
                    if (incarico.sumMinStrInt <= 2880 && !incarico.isNotturna) {
                        hours += parseInt(split[0]);
                        minutes += parseInt(split[1]);
                        hours += Math.floor(minutes / 60);
                        minutes = minutes % 60;
                    }
                }
                else {
                    if (incarico.sumMinStrInt > 2880 && !incarico.isNotturna) {
                        hours += parseInt(split[0]);
                        minutes += parseInt(split[1]);
                        hours += Math.floor(minutes / 60);
                        minutes = minutes % 60;
                    }
                }
            }
        });
        if (hours == 0 && minutes == 0) {
            return "00:00"
        } else {
            return hours + ':' + formattedNumber(minutes)
        }
    }

    const isNotturna = (orario) => {
        const split = orario.split(':');
        let ora = parseInt(split[0]);
        if (ora > 21 || ora < 6) {
            return true
        } else {
            return false
        }
    }

    const formattedNumber = (num) => ("0" + num).slice(-2);


    const getFirstLetterOfDay = (day) => {
        switch (day) {
            case 0:
                day = "D";
                break;
            case 1:
                day = "L";
                break;
            case 2:
                day = "M";
                break;
            case 3:
                day = "M";
                break;
            case 4:
                day = "G";
                break;
            case 5:
                day = "V";
                break;
            case 6:
                day = "S";
        }
        return day
    }


    const calcoloOreLavorateString = (incarichi) => {
        let hours = 0, minutes = 0;
        incarichi.forEach((incarico) => {
            if (incarico.ore) {
                const split = incarico.ore.split(':');
                hours += parseInt(split[0]);
                minutes += parseInt(split[1]);
            }
        });
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;
        return formattaOre(hours) + ':' + formattaMinuti(minutes)
    }

    const formattaOre = (ore) => {
        return (ore <= 9 ? "0" : "") + ore
    }

    const formattaMinuti = (minuti) => {
        return (minuti <= 9 ? "0" : "") + minuti;
    }

    const findColonnaPersonalizzata1 = (array) => {
        let arrayColonnePersonallizate = [];
        array.incarichi.map((incarico) => {
            if (incarico.oreSciopero) {
                if (!arrayColonnePersonallizate.includes("oreSciopero")) {
                    arrayColonnePersonallizate.push("oreSciopero")
                }
            }
            if (incarico.oreAllattamento) {
                if (!arrayColonnePersonallizate.includes("oreAllattamento")) {
                    arrayColonnePersonallizate.push("oreAllattamento")
                }
            }
            if (incarico.permessiL104) {
                if (!arrayColonnePersonallizate.includes("permessiL104")) {
                    arrayColonnePersonallizate.push("permessiL104")
                }
            }
            if (incarico.oreROLGoduti) {
                if (!arrayColonnePersonallizate.includes("oreROLGoduti")) {
                    arrayColonnePersonallizate.push("oreROLGoduti")
                }
            }
            if (incarico.bancaOreGoduta) {
                if (!arrayColonnePersonallizate.includes("bancaOreGoduta")) {
                    arrayColonnePersonallizate.push("bancaOreGoduta")
                }
            }
        }
        )
        if (arrayColonnePersonallizate.length == 0) {
            arrayColonnePersonallizate.push("")
        }
        if (arrayColonnePersonallizate.length == 1) {
            arrayColonnePersonallizate.push("")
        }
        return arrayColonnePersonallizate
    }

    const eliminaPDFPopUp = (idPdf, i) => {
        let answer = window.confirm("Sei sicuro?");
        if (answer) {
            if (i == indexToPrint) {
                setIndexPrint(-1)
            }
            eliminaPDF(idPdf)
        }
        return
    }

    const onClickGeneraTRRIPA = (i) => {
        generaFileTRRIPA([incarichiDipendenteSalvatiArray[i]], user)
    }


    const onClickOpenGeneraNEWTRRIPA = (i) => {
        console.log(incarichiDipendenteSalvatiArray)
        generaFileNEWTRRIPA({incarichi: incarichiDipendenteSalvatiArray[i].incarichi, indennita: incarichiDipendenteSalvatiArray[i].indennita, user: user, dipendente: incarichiDipendenteSalvatiArray[i].dipendente})
    }

    const getLogo = (idOrganizzazione) => {
		if(idOrganizzazione == '63e618049a5309e9f6f3ff9a'){
			return 'logo_emilpol.jpg'
		}else if(idOrganizzazione == '63e618279a5309e9f6f3ff9b'){
			return 'new_koop.png'
		}else{
			return 'logo_vigili_dell_ordine.png'
		}
	}


    return (
            <div className="div_wrap_50">
                <div className="div_container">
                    <div className="title_window">
                        <div className="title_window_p">Salvataggi dal 1-1-{dataInizioInput.getFullYear()} al 31-12-{dataInizioInput.getFullYear()}</div>
                    </div>
                    <div className="dati_container">
                        <ul className='lista_pdf_ul'>
                            {incarichiDipendenteSalvatiArray.map((pdf, i) => (
                                <li className='mb-5'>
                                    <div className='esterni'>
                                        <a className="lista_pdf" onClick={() => handlePrint(i)}><Moment format='DD/MM/YYYY'>{pdf.dataInizio}</Moment> - <Moment format='DD/MM/YYYY'>{pdf.dataFine}</Moment> (creato in data: <Moment format='DD/MM/YYYY'>{pdf.dataSalvataggio}</Moment>)</a>
                                        <div className='trripa-button' onClick={() => { onClickGeneraTRRIPA(i) }}>TRRIPA</div>
                                        {false ? <div className='new-trripa-button' onClick={() => { onClickOpenGeneraNEWTRRIPA(i) }}> NEW TRRIPA</div> : ''}
                                        <div className='cestino' onClick={() => { eliminaPDFPopUp(pdf._id, i) }}><img src="assets/media/logos/trash-can.png" /></div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {indexToPrint >= 0 ?
                    <div className="div_container">
                        <div className="dati_container" ref={printRef}>
                            <div ref={componentRef} className="page">
                                <div className="div_wrap_100">
                                    <div className="div_wrap_50">
                                    <img src={`assets/media/logos/${getLogo(user.organizzazione._id)}`} loading="lazy" alt="" className="logo_header" />
                                        <h5>{user.filiale.nome}</h5>
                                    </div>
                                    <div className="div_wrap_50">
                                        <h4>{incarichiDipendenteSalvatiArray[indexToPrint].dipendente.cognome} {incarichiDipendenteSalvatiArray[indexToPrint].dipendente.nome} - mat. {incarichiDipendenteSalvatiArray[indexToPrint].dipendente.matricola}</h4>
                                        <h5>Cod. Fisc: {incarichiDipendenteSalvatiArray[indexToPrint].dipendente.codicefiscale}</h5>
                                        <h5>Incarichi di <Moment format='MMMM'>{incarichiDipendenteSalvatiArray[indexToPrint].dataInizio}</Moment></h5>
                                    </div>
                                </div>
                                {incarichiDipendenteSalvatiArray[indexToPrint].dipendente.qualifica == 'gpg' ?
                                    <>
                                        <table style={tableStyle}>
                                            <thead>
                                                <tr style={tableInt}>
                                                    <th style={tableStyle4}></th>
                                                    <th style={tableStyle4}></th>
                                                    <th style={tableStyle3}>Dis.</th>
                                                    <th style={tableStyle3}>Eff.</th>
                                                    <th style={tableStyle2}>Dalle</th>
                                                    <th style={tableStyle2}>Alle</th>
                                                    <th style={tableStyle2}>Ore<br />ordinarie<br />120</th>
                                                    <th style={tableStyle2}>BancaOre<br />Retrib.<br />159</th>
                                                    <th style={tableStyle2}>BancaOre<br />da Fru.<br />160</th>
                                                    <th style={tableStyle2}>Ore Str.<br />351</th>
                                                    <th style={tableStyle2}>Ore<br />Str.Fest.<br />053</th>
                                                    <th style={tableStyle2}>Ore<br />Str.Rip.Sp<br />014</th>
                                                    <th style={tableStyle2}>Ind.<br />Dom.<br />061</th>
                                                    <th style={tableStyle2}>Ore sup.<br />part-time</th>
                                                    <th style={tableStyle2}>Fless.</th>
                                                    <th style={tableStyle2}>Ore<br />Non Lav.</th>
                                                    <th style={tableStyle2}>{findColonnaPersonalizzata1(incarichiDipendenteSalvatiArray[indexToPrint])[0]}</th>
                                                    <th style={tableStyle2}>{findColonnaPersonalizzata1(incarichiDipendenteSalvatiArray[indexToPrint])[1]}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {incarichiDipendenteSalvatiArray[indexToPrint].incarichi.map((el, i) => (
                                                    <tr style={tableStyle2} className={el.isLastMultiplo ? "grey" : ""}>
                                                        <td style={tableStyle4}>{getFirstLetterOfDay(parseInt(el.giornoSettimana))}</td>
                                                        <td style={tableStyle4}>{el.data.split('-')[0]}</td>
                                                        <td style={tableStyle3}>{el.disponibilita}</td>
                                                        <td style={tableStyle3}>{el.effettivo}</td>
                                                        <td style={tableStyle2}>{el.oraInizio}</td>
                                                        <td style={tableStyle2}>{el.oraFine}</td>
                                                        <td style={tableStyle2}>{el.oreOrdinarie}</td>
                                                        <td style={tableStyle2}>{el.bancaOreRetrib}</td>
                                                        <td style={tableStyle2}>{el.bancaOreDaFruire}</td>
                                                        <td style={tableStyle2}>{el.oreStraordinarie}</td>
                                                        <td style={tableStyle2}>{el.oreStraordinarieFestive}</td>
                                                        <td style={tableStyle2}>{el.oreStrRipSp}</td>
                                                        <td style={tableStyle2}>{el.indennitaDomenicale}</td>
                                                        <td style={tableStyle2}>{el.oreSupPartTime}</td>
                                                        <td style={tableStyle2}>{el.flessibilita}</td>
                                                        <td style={tableStyle2}>{el.oreNonLavorate}</td>
                                                        <td style={tableStyle2}>{el[findColonnaPersonalizzata1(incarichiDipendenteSalvatiArray[indexToPrint])[0]]}</td>
                                                        <td style={tableStyle2}>{el[findColonnaPersonalizzata1(incarichiDipendenteSalvatiArray[indexToPrint])[1]]}</td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <span></span>
                                        <table style={tableStyle}>
                                            <thead>
                                                <tr style={tableStyle2}>
                                                    <th style={tableStyle5}>TOTALE ORE:</th>
                                                    <th style={tableStyle2}>{calcoloOreLavorateString(incarichiDipendenteSalvatiArray[indexToPrint].incarichi)}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreOrdinarie")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "bancaOreRetrib")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "bancaOreDaFruire")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreStraordinarie")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreStraordinarieFestive")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreStrRipSp")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "indennitaDomenicale")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreSupPartTime")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "flessibilita")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreNonLavorate")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, findColonnaPersonalizzata1(incarichiDipendenteSalvatiArray[indexToPrint])[0])}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, findColonnaPersonalizzata1(incarichiDipendenteSalvatiArray[indexToPrint])[1])}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </>
                                    :
                                    <>
                                        <table style={tableStyle}>
                                            <thead>
                                                <tr style={tableInt}>
                                                    <th style={tableStyle4}></th>
                                                    <th style={tableStyle4}></th>
                                                    <th style={tableStyle3}>Dis.</th>
                                                    <th style={tableStyle3}>Eff.</th>
                                                    <th style={tableStyle2}>Dalle</th>
                                                    <th style={tableStyle2}>Alle</th>
                                                    <th style={tableStyle2}>Ore<br />ordinarie</th>
                                                    <th style={tableStyle2}>Ore<br />str. 1.</th>
                                                    <th style={tableStyle2}>Ore<br />str. 2</th>
                                                    <th style={tableStyle2}>Ore str.<br />notte</th>
                                                    <th style={tableStyle2}>Ore str<br />fest. giorno</th>
                                                    <th style={tableStyle2}>Ore str<br />fest. notte</th>
                                                    <th style={tableStyle2}>Dom.<br />giorno</th>
                                                    <th style={tableStyle2}>Dom.<br />notte</th>
                                                    <th style={tableStyle2}>Ore sup.<br />part-time</th>
                                                    <th style={tableStyle2}>Fless.</th>
                                                    <th style={tableStyle2}>Ore<br />Non Lav.</th>
                                                    <th style={tableStyle2}>{findColonnaPersonalizzata1(incarichiDipendenteSalvatiArray[indexToPrint])[0]}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {incarichiDipendenteSalvatiArray[indexToPrint].incarichi.map((el, i) => (
                                                    <tr style={tableStyle2} className={el.isLastMultiplo ? "grey" : ""}>
                                                        <td style={tableStyle4}>{getFirstLetterOfDay(parseInt(el.giornoSettimana))}</td>
                                                        <td style={tableStyle4}>{el.data.split('-')[0]}</td>
                                                        <td style={tableStyle3}>{el.disponibilita}</td>
                                                        <td style={tableStyle3}>{el.effettivo}</td>
                                                        <td style={tableStyle2}>{el.oraInizio}</td>
                                                        <td style={tableStyle2}>{el.oraFine}</td>
                                                        <td style={tableStyle2}>{el.oreOrdinarie}</td>
                                                        <td style={tableStyle2}>{el.sumMinStrInt > 2880 ? null : el.oreStraordinarie}</td>
                                                        <td style={tableStyle2}>{el.sumMinStrInt > 2880 ? el.oreStraordinarie : null}</td>
                                                        <td style={tableStyle2}>{el.oreStraordinarieNotteOTS}</td>
                                                        <td style={tableStyle2}>{el.oreStraordinarieFestiveOTSGiorno}</td>
                                                        <td style={tableStyle2}>{el.oreStraordinarieFestiveOTSNotte}</td>
                                                        <td style={tableStyle2}>{el.indennitaDomenicaleOTSGiorno}</td>
                                                        <td style={tableStyle2}>{el.indennitaDomenicaleOTSNotte}</td>
                                                        <td style={tableStyle2}>{el.oreSupPartTime}</td>
                                                        <td style={tableStyle2}>{el.flessibilita}</td>
                                                        <td style={tableStyle2}>{el.oreNonLavorate}</td>
                                                        <td style={tableStyle2}>{el[findColonnaPersonalizzata1(incarichiDipendenteSalvatiArray[indexToPrint])[0]]}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <span></span>
                                        <table style={tableStyle}>
                                            <thead>
                                                <tr style={tableStyle2}>
                                                    <th style={tableStyle5}>TOTALE ORE:</th>
                                                    <th style={tableStyle2}>{calcoloOreLavorateString(incarichiDipendenteSalvatiArray[indexToPrint].incarichi)}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreOrdinarie")}</th>
                                                    {incarichiDipendenteSalvatiArray[indexToPrint].calcoloStrDivisoOTS == true ? 
                                                        <>
                                                        <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreStraordinarieOTS1")}</th>
                                                        <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreStraordinarieOTS2")}</th>
                                                        </>
                                                        :
                                                        <>
                                                        <th style={tableStyle2}>{calcoloTotaliOreStrOTS(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreStraordinarieMin48")}</th>
                                                        <th style={tableStyle2}>{calcoloTotaliOreStrOTS(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreStraordinarieMax48")}</th>
                                                        </>
                                                        }
                                                    <th style={tableStyle2}>{calcoloTotaliOreStrOTS(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreStraordinarieNotte")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreStraordinarieFestiveOTSGiorno")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreStraordinarieFestiveOTSNotte")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "indennitaDomenicaleOTSGiorno")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "indennitaDomenicaleOTSNotte")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreSupPartTime")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "flessibilita")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, "oreNonLavorate")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(incarichiDipendenteSalvatiArray[indexToPrint].incarichi, findColonnaPersonalizzata1(incarichiDipendenteSalvatiArray[indexToPrint])[0])}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </>
                                }
                                <table style={tableStyle}>
                                    <thead>
                                        <tr style={tableStyle2}>
                                            <th style={tableStyle5}>TOTALE GIORNI:</th>
                                            <th style={tableStyle2}>{calcoloGiorniLavorati(incarichiDipendenteSalvatiArray[indexToPrint].incarichi)}</th>
                                            <th style={tableStyle2}></th>
                                            <th style={tableStyle2}></th>
                                            <th style={tableStyle2}></th>
                                            <th style={tableStyle2}></th>
                                            <th style={tableStyle2}></th>
                                            <th style={tableStyle2}></th>
                                            <th style={tableStyle2}></th>
                                            <th style={tableStyle2}></th>
                                            <th style={tableStyle2}></th>
                                            <th style={tableStyle2}></th>
                                            <th style={tableStyle2}></th>
                                            <th style={tableStyle2}></th>
                                        </tr>
                                    </thead>
                                </table>
                                <div className="div_wrap_100">
                                    <div className="div_wrap_50">
                                        <div className="div_container minimal first">
                                            <table style={tableStyleIndennita}>
                                                <thead>
                                                    <tr style={tableInt}>
                                                        <th style={tableStyleIndennitaVoce}>Voce</th>
                                                        <th style={tableStyleIndennitaOreGiorni}>Ore/Giorni</th>
                                                        <th style={tableStyleIndennitaImporto}>Importo</th>
                                                        <th style={tableStyleIndennitaNote}>Annotazioni</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {incarichiDipendenteSalvatiArray[indexToPrint].indennita.map((el, i) => (
                                                        i < (incarichiDipendenteSalvatiArray[indexToPrint].indennita.length / 2) ?
                                                            <tr style={tableStyle2}>
                                                                <td style={tableStyleIndennitaVoce}>{el.codice}</td>
                                                                <td style={tableStyleIndennitaOreGiorni}>{el.modalita == 'ore' ? el.oreGiorni > 0 ? (Number(el.oreGiorni) / 60).toFixed(2) : 0 : el.oreGiorni}</td>
                                                                <td style={tableStyleIndennitaImporto}>{el.importo}</td>
                                                                <td style={tableStyleIndennitaNote}>{el.annotazioni}</td>
                                                            </tr>
                                                            :
                                                            ''
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="div_wrap_50">
                                        <div className="div_container minimal last">
                                            <table style={tableStyleIndennita}>
                                                <thead>
                                                    <tr style={tableInt}>
                                                        <th style={tableStyleIndennitaVoce}>Voce</th>
                                                        <th style={tableStyleIndennitaOreGiorni}>Ore/Giorni</th>
                                                        <th style={tableStyleIndennitaImporto}>Importo</th>
                                                        <th style={tableStyleIndennitaNote}>Annotazioni</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {incarichiDipendenteSalvatiArray[indexToPrint].indennita.map((el, i) => (
                                                        i >= (incarichiDipendenteSalvatiArray[indexToPrint].indennita.length / 2) ?
                                                            <tr style={tableStyle2}>
                                                                <td style={tableStyleIndennitaVoce}>{el.codice}</td>
                                                                <td style={tableStyleIndennitaOreGiorni}>{el.modalita == 'ore' ? el.oreGiorni > 0 ? (Number(el.oreGiorni) / 60).toFixed(2) : 0 : el.oreGiorni}</td>
                                                                <td style={tableStyleIndennitaImporto}>{el.importo}</td>
                                                                <td style={tableStyleIndennitaNote}>{el.annotazioni}</td>
                                                            </tr>
                                                            :
                                                            ''
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <table style={tableStyleNote}>
                                    <thead>
                                        <tr style={{ textAlign: "left" }}>
                                            <th>NOTE: <span style={{ fontWeight: "400" }}>{incarichiDipendenteSalvatiArray[indexToPrint].note}</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="item_3 m_top_5">
                                <div className="list_button">
                                    <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                        <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${incarichiDipendenteSalvatiArray[indexToPrint].length == 0 ? "button_disabled" : ""}`} onClick={print}>Stampa</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ''
                }
            </div>
    )
}

PDFIncarichiGPG.protoTypes = {
    getPDFIncarichiDipendenteSalvati: PropTypes.func.isRequired,
    eliminaPDF: PropTypes.func.isRequired,
    dipendente: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    dipendente: state.dipendente,
    alert: state.alert,
    auth: state.auth
});

export default connect(mapStateToProps, { getPDFIncarichiDipendenteSalvati, eliminaPDF })(PDFIncarichiGPG);