const spazio = String.fromCharCode(32)
let spazio5 = spazio+spazio+spazio+spazio+spazio;
let spazio10 = spazio5 + spazio5;
let spazio20 = spazio10 + spazio10;
const fileprovaJSON = require ('./trippa.json');

const checkIfMultiploOrLastMultiplo = (incarico) => {
    if((!incarico.multiplo || (incarico.multiplo && incarico.isLastMultiplo ))){
        return true
    }else{
        return false
    }
}

const getOreString = (ore) => {
    if(ore && ore.length > 0){
        const split = ore.split(':');
        return split[0]
    }else{
        return '00'
    }    
}

const getMinutiString = (ore) => {
    if(ore && ore.length > 0){
        const split = ore.split(':');
        return split[1]
    }else{
        return '00'
    }  
}

const stampaTRRIPA = (file) => {
    const fileData = JSON.stringify(file);
    const blob = new Blob([file], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "TRRIPA.D";
    link.href = url;
    link.click();
}

const stampaNEWTRRIPA = (file) => {
    const fileData = JSON.stringify(file);
    const blob = new Blob([file], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "TRRIPA.xml";
    link.href = url;
    link.click();
}

const generaRigaIndennitaArrayFile = (indennita, anno, mese) => {
    console.log(indennita)
    let arrrayIndennita = []
    let indennitaFilter = indennita.filter(el => el.oreGiorni > 0 || el.importo)
    if(indennitaFilter.length > 0){
        indennitaFilter.map(a =>{
            let numQuan1;
            let numQuan2;
            let resto;
            if(a.modalita == 'ore'){
                numQuan1 = '0000000';
                numQuan2 = '00000';
                let minuti = 0;
                let ore = 0;
                let oreIndennita = a.oreGiorni/60
                oreIndennita = String(parseFloat(oreIndennita).toFixed(2))
                minuti = oreIndennita.substring(oreIndennita.indexOf(".")+1, oreIndennita.length)
                ore = oreIndennita.substring(0, oreIndennita.indexOf("."))

                numQuan1 = numQuan1 + ore
                numQuan2 = minuti + numQuan2
                numQuan1 = numQuan1.substring(numQuan1.length - 7,numQuan1.length)
                numQuan2 = numQuan2.substring(0, 5)

                //let oreFloat = parseFloat(a.oreGiorni)
                //oreFloat = oreFloat.toFixed(2)
                //let oreFloatString = String(oreFloat)
                //let oreString = oreFloatString.toString().split(".")[0]
                //let minutiString = oreFloatString.toString().split(".")[1]
                //minutiString = parseFloat("0."+minutiString)
                //ore = oreString
                //minuti = String(( minutiString* 30)/0.5)
                //numQuan1 = numQuan1.substring(0, 7 - ore.length)
                //numQuan1 = numQuan1 + ore
                //numQuan2 = numQuan2.substring(0, 5 - minuti.length)
                //numQuan2 = minuti + numQuan2
                resto = "000000000000000000000000000000000000000000000000000000000000"+ spazio + "0000" + spazio
            }else if(a.modalita == 'giorni'){
                numQuan1 = '0000000';
                numQuan2 = '00000';
                let giorni = String(a.oreGiorni)
                numQuan1 = numQuan1.substring(0, 7 - giorni.length)
                numQuan1 = numQuan1 + giorni
                resto = "000000000000000000000000000000000000000000000000000000000000"+ spazio + "0000" + spazio
            }else{
                numQuan1 = '0000000';
                numQuan2 = '00000';
                let importo = parseFloat(a.importo).toFixed(2)
                let importoString = String(importo);
                let interi = importoString.split(".")[0]
                let virgola = importoString.split(".")[1]
                interi = "000000000" + interi
                interi = interi.substring(interi.length - 9, interi.length )
                virgola = "00" + virgola
                virgola = virgola.substring(2, 4)


                //let interiString = "0000000" + interi 
                //interiString = interiString.substring(interiString.length - 7, interiString.length)
                //let virgolaString = virgola + "00000"
                //virgolaString = virgolaString.substring(0, 5)
                resto = "00000000000000"+ "00000000000000"+interi+virgola+"00000000"+"00000000"+ "00000"+ spazio + "0000" + spazio

                //resto = interiString + virgolaString + "000000000000000000000000000000000000000000000000"+ spazio + "0000" + spazio
            }
            let newObjDip = {
                codTipoRec: '02',
                CodVoceRP: spazio + spazio + a.codice,
                CodSeznVocePA: 'R',
                CodVocePA: '0000',
                DataPerdElabn: anno + mese + '00',
                CodTipoCedl: '0',
                //CodTipoVoce: a.modalita == 'giorni' ? 'G' : 'H',
                CodTipoVoce: a.modalita == 'giorni' ? 'G' : a.modalita == 'ore' ? 'H' : 'I',
                NumQuan: numQuan1 + numQuan2,
                resto: resto
            }
            arrrayIndennita.push({codice: "", riga: newObjDip.codTipoRec + newObjDip.CodVoceRP + newObjDip.CodSeznVocePA + newObjDip.CodVocePA + newObjDip.DataPerdElabn + newObjDip.CodTipoCedl + newObjDip.CodTipoVoce + newObjDip.NumQuan + newObjDip.resto})
        })
    }
    return arrrayIndennita
}

const checkIsEqualTRRIPA = (file, fileProva) => {
    let arrayFile = file.match(/.{1,100}/g);
    let arrayFileProva = fileProva.dati.match(/.{1,100}/g);
    arrayFileProva.map((e, i) => {
        if(arrayFile[i]){
            if(e == arrayFile[i]){
                console.log(i + " GIUSTO")
            }else{
                console.log(i + " SBAGLIATA")
                console.log(arrayFile[i])
                console.log(e)
            }
        }else{
            console.log("NON ESISTE")
        }
    })
}

const ordinaFileTRRIPA = (array) => {
    let a = array.filter(a => { return a.codice == "01"})
    let b = array.filter(a => { return a.codice == "159"})
    let c = array.filter(a => { return a.codice == "570"})
    let d = array.filter(a => { return a.codice == "351"})
    let e = array.filter(a => { return a.codice != "01" && a.codice != "159" && a.codice != "570" && a.codice != "351"})
    var arrayFinale = [...new Set([...a, ...b, ...c, ...d, ...e])];
    return arrayFinale
}

const aggiungiFiller = (testo) => {
    let x = 100 - testo.length
    if(x >0){
        for(let i = 0; i<x; i ++){
            testo = testo + spazio
        }
    }
    return testo
}

const fromStringToMinuti = (oreString) => {
    var minuti = getMinutiInt(oreString);
    var ore = getOreInt(oreString) * 60
    return ore + minuti
}

const fromMinutiToOreString = (minutiTotali) => {
    if (minutiTotali > 0) {
        var minuti = minutiTotali % 60;
        var ore = (minutiTotali - minuti) / 60;
        if (ore == 0 && minuti == 0) {
            return ""
        } else {
            return formattaOre(ore) + ":" + formattaMinuti(minuti)
        }
    } else {
        return ""
    }
}

const getOreInt = (ore) => {
    if (ore && ore.length > 0) {
        const split = ore.split(':');
        return parseInt(split[0])
    } else {
        return 0
    }
}

const getMinutiInt = (ore) => {
    if (ore && ore.length > 0) {
        const split = ore.split(':');
        return parseFloat(split[1])
    } else {
        return 0
    }
}

const formattaOre = (ore) => {
    return (ore <= 9 ? "0" : "") + ore
}

const formattaMinuti = (minuti) => {
    return (minuti <= 9 ? "0" : "") + minuti;
}

const calcolaStraordinari48 = (incarico) => {
    let minutiStraordinari = fromStringToMinuti(incarico.oreStraordinarie)
    let diffSumMinTotInt;
    let minutiNotte = 0;
    if (minutiStraordinari > 0) {
        if (incarico.sumMinTotInt > 2880) {
            diffSumMinTotInt = incarico.sumMinTotInt - minutiStraordinari
            if (diffSumMinTotInt < 2880) {
                let calcoloStr2 = incarico.sumMinTotInt - 2880
                let calcoloStr1 = 2880 - diffSumMinTotInt
                return { primaColonna: fromMinutiToOreString(calcoloStr1), secondaColonna: fromMinutiToOreString(calcoloStr2) }
            } else {
                return { primaColonna: "", secondaColonna: fromMinutiToOreString(minutiStraordinari) }
            }
        } else {
            return { primaColonna: fromMinutiToOreString(minutiStraordinari), secondaColonna: "" }
        }
    } else {
        return { primaColonna: "", secondaColonna: "" }
    }
}


const generaRigaTurniArrayFileGPGBOLD = (incarico, newObjSer, dipendente) => {
    let array = []
    if(incarico.disponibilita == 'DI' && incarico.effettivo == 'AS'){
        if(incarico.oreOrdinarie.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + spazio + '01'
            newObjSer.NumOreMovmn = getOreString(incarico.oreOrdinarie);
            newObjSer.NumMinMovmn = getMinutiString(incarico.oreOrdinarie);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.bancaOreRetrib.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '159'
            newObjSer.NumOreMovmn = getOreString(incarico.bancaOreRetrib);
            newObjSer.NumMinMovmn = getMinutiString(incarico.bancaOreRetrib);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.oreStraordinarie.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '351'
            newObjSer.NumOreMovmn = getOreString(incarico.oreStraordinarie);
            newObjSer.NumMinMovmn = getMinutiString(incarico.oreStraordinarie);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
    }else if(incarico.effettivo == 'RR'){
        newObjSer.VFGioRip = 'S'
        newObjSer.CodGiusvRP = spazio5
        newObjSer.NumOreMovmn = '00';
        newObjSer.NumMinMovmn = '00';
        let obj = {riga: "", codice: ""}
        obj.riga = newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    }else if(incarico.disponibilita == 'RP' && incarico.effettivo == 'AS'){
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = spazio + spazio + spazio +'AS'
        newObjSer.NumOreMovmn = getOreString(incarico.oreOrdinarie);
        newObjSer.NumMinMovmn = getMinutiString(incarico.oreOrdinarie);
        let obj = {riga: "", codice: ""}
        obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
        if(incarico.bancaOreRetrib.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '159'
            newObjSer.NumOreMovmn = getOreString(incarico.bancaOreRetrib);
            newObjSer.NumMinMovmn = getMinutiString(incarico.bancaOreRetrib);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
    }else {
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = spazio + spazio + spazio + incarico.effettivo
        newObjSer.NumOreMovmn = '00';
        newObjSer.NumMinMovmn = '00';
        let obj = {riga: "", codice: ""}
        obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    }
    if(incarico.indennitaDomenicale.length > 0){
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = spazio + spazio + '061'
        newObjSer.NumOreMovmn = getOreString(incarico.indennitaDomenicale);
        newObjSer.NumMinMovmn = getMinutiString(incarico.indennitaDomenicale);
        let obj = {riga: "", codice: ""}
        obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    }
    if(incarico.bancaOreDaFruire.length > 0){
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = spazio + spazio + '160'
        newObjSer.NumOreMovmn = getOreString(incarico.bancaOreDaFruire);
        newObjSer.NumMinMovmn = getMinutiString(incarico.bancaOreDaFruire);
        let obj = {riga: "", codice: ""}
        obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    }
    if(incarico.oreStraordinarieFestive.length > 0){
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = spazio + spazio + '293'
        newObjSer.NumOreMovmn = getOreString(incarico.oreStraordinarieFestive);
        newObjSer.NumMinMovmn = getMinutiString(incarico.oreStraordinarieFestive);
        let obj = {riga: "", codice: ""}
        obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    }
    if(incarico.flessibilita.length > 0){
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = spazio + spazio + '999'
        newObjSer.NumOreMovmn = getOreString(incarico.flessibilita);
        newObjSer.NumMinMovmn = getMinutiString(incarico.flessibilita);
        let obj = {riga: "", codice: ""}
        obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    }
    return array
}

const generaRigaTurniArrayFileGPGB = (incarico, newObjSer, dipendente) => {
    let array = []
    let oreFerOrdinarie = dipendente.ferOrd.replace('.', ':');

    if(incarico.effettivo == 'AS'){
        if(incarico.oreOrdinarie.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + spazio + '01'
            newObjSer.NumOreMovmn = getOreString(incarico.oreOrdinarie);
            newObjSer.NumMinMovmn = getMinutiString(incarico.oreOrdinarie);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.bancaOreRetrib.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '159'
            newObjSer.NumOreMovmn = getOreString(incarico.bancaOreRetrib);
            newObjSer.NumMinMovmn = getMinutiString(incarico.bancaOreRetrib);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.oreStraordinarie.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '351'
            newObjSer.NumOreMovmn = getOreString(incarico.oreStraordinarie);
            newObjSer.NumMinMovmn = getMinutiString(incarico.oreStraordinarie);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.indennitaDomenicale.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '061'
            newObjSer.NumOreMovmn = getOreString(incarico.indennitaDomenicale);
            newObjSer.NumMinMovmn = getMinutiString(incarico.indennitaDomenicale);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.bancaOreDaFruire.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '160'
            newObjSer.NumOreMovmn = getOreString(incarico.bancaOreDaFruire);
            newObjSer.NumMinMovmn = getMinutiString(incarico.bancaOreDaFruire);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.oreStraordinarieFestive.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '053'
            newObjSer.NumOreMovmn = getOreString(incarico.oreStraordinarieFestive);
            newObjSer.NumMinMovmn = getMinutiString(incarico.oreStraordinarieFestive);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.oreStrRipSp.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '014'
            newObjSer.NumOreMovmn = getOreString(incarico.oreStrRipSp);
            newObjSer.NumMinMovmn = getMinutiString(incarico.oreStrRipSp);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.oreSupPartTime.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '072'
            newObjSer.NumOreMovmn = getOreString(incarico.oreSupPartTime);
            newObjSer.NumMinMovmn = getMinutiString(incarico.oreSupPartTime);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
    }
    if(incarico.effettivo == 'AS'){
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = spazio + spazio + spazio +'AS'
        newObjSer.NumOreMovmn = getOreString(incarico.oreOrdinarie);
        newObjSer.NumMinMovmn = getMinutiString(incarico.oreOrdinarie);
        let obj = {riga: "", codice: ""}
        obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    } else if(incarico.effettivo == 'RR' || incarico.effettivo == 'RP'){
        newObjSer.VFGioRip = 'S'
        newObjSer.CodGiusvRP = spazio5
        newObjSer.NumOreMovmn = getOreString(oreFerOrdinarie);
        newObjSer.NumMinMovmn = getMinutiString(oreFerOrdinarie);
        let obj = {riga: "", codice: ""}
        obj.riga = newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    }else{
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = spazio + spazio + spazio + incarico.effettivo
        newObjSer.NumOreMovmn = getOreString(oreFerOrdinarie);
        newObjSer.NumMinMovmn = getMinutiString(oreFerOrdinarie);
        let obj = {riga: "", codice: ""}
        obj.riga = newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    }
    if(incarico.flessibilita > 0){
        let fless = fromMinutiToOreString(incarico.flessibilita)
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = spazio + spazio + '999'
        newObjSer.NumOreMovmn = getOreString(fless);
        newObjSer.NumMinMovmn = getMinutiString(fless);
        let obj = {riga: "", codice: ""}
        obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    }
    return array
}

const generaRigaTurniArrayFileOTSB = (incarico, newObjSer, dipendente, calcoloStrDivisoOTS) => {
    let array = []
    let oreFerOrdinarie = dipendente.ferOrd.replace('.', ':');
    if(incarico.effettivo == 'AS'){
        //if(incarico.disponibilita == 'DI'){
            if(incarico.oreOrdinarie.length > 0){
                newObjSer.VFGioRip = 'N'
                newObjSer.CodGiusvRP = spazio + spazio + spazio + '01'
                newObjSer.NumOreMovmn = getOreString(incarico.oreOrdinarie);
                newObjSer.NumMinMovmn = getMinutiString(incarico.oreOrdinarie);
                let obj = {riga: "", codice: ""}
                obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
                obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
                obj.riga = aggiungiFiller(obj.riga)
                array.push(obj)
            }
       // }
        let oreStraordinarie;
        let oreMinori48;
        let oreMaggiori48;
        if(calcoloStrDivisoOTS){
            oreMinori48 = incarico.oreStraordinarieOTS1
            oreMaggiori48 = incarico.oreStraordinarieOTS2
        }else{
            oreStraordinarie = calcolaStraordinari48(incarico)
            oreMinori48 = oreStraordinarie.primaColonna
            oreMaggiori48 = oreStraordinarie.secondaColonna
        }
        
        if(oreMinori48.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '570'
            newObjSer.NumOreMovmn = getOreString(oreMinori48);
            newObjSer.NumMinMovmn = getMinutiString(oreMinori48);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(oreMaggiori48.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '351'
            newObjSer.NumOreMovmn = getOreString(oreMaggiori48);
            newObjSer.NumMinMovmn = getMinutiString(oreMaggiori48);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.oreStraordinarieNotteOTS.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '578'
            newObjSer.NumOreMovmn = getOreString(incarico.oreStraordinarieNotteOTS);
            newObjSer.NumMinMovmn = getMinutiString(incarico.oreStraordinarieNotteOTS);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.oreStraordinarieFestiveOTSGiorno.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '572'
            newObjSer.NumOreMovmn = getOreString(incarico.oreStraordinarieFestiveOTSGiorno);
            newObjSer.NumMinMovmn = getMinutiString(incarico.oreStraordinarieFestiveOTSGiorno);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.oreStraordinarieFestiveOTSNotte.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '577'
            newObjSer.NumOreMovmn = getOreString(incarico.oreStraordinarieFestiveOTSNotte);
            newObjSer.NumMinMovmn = getMinutiString(incarico.oreStraordinarieFestiveOTSNotte);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.indennitaDomenicaleOTSGiorno.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '890'
            newObjSer.NumOreMovmn = getOreString(incarico.indennitaDomenicaleOTSGiorno);
            newObjSer.NumMinMovmn = getMinutiString(incarico.indennitaDomenicaleOTSGiorno);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.indennitaDomenicaleOTSNotte.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '891'
            newObjSer.NumOreMovmn = getOreString(incarico.indennitaDomenicaleOTSNotte);
            newObjSer.NumMinMovmn = getMinutiString(incarico.indennitaDomenicaleOTSNotte);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
        if(incarico.oreSupPartTime.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = spazio + spazio + '072'
            newObjSer.NumOreMovmn = getOreString(incarico.oreSupPartTime);
            newObjSer.NumMinMovmn = getMinutiString(incarico.oreSupPartTime);
            let obj = {riga: "", codice: ""}
            obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
            obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
            obj.riga = aggiungiFiller(obj.riga)
            array.push(obj)
        }
    }
    if(incarico.effettivo == 'AS'){
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = spazio + spazio + spazio +'AS'
        newObjSer.NumOreMovmn = getOreString(incarico.oreOrdinarie);
        newObjSer.NumMinMovmn = getMinutiString(incarico.oreOrdinarie);
        let obj = {riga: "", codice: ""}
        obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    } else if(incarico.effettivo == 'RR' || incarico.effettivo == 'RP'){
        newObjSer.VFGioRip = 'S'
        newObjSer.CodGiusvRP = spazio5
        newObjSer.NumOreMovmn = getOreString(oreFerOrdinarie);
        newObjSer.NumMinMovmn = getMinutiString(oreFerOrdinarie);
        let obj = {riga: "", codice: ""}
        obj.riga = newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    }else{
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = spazio + spazio + spazio + incarico.effettivo
        newObjSer.NumOreMovmn = getOreString(oreFerOrdinarie);
        newObjSer.NumMinMovmn = getMinutiString(oreFerOrdinarie);
        let obj = {riga: "", codice: ""}
        obj.riga = newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    }
    if(incarico.flessibilita > 0){
        let fless = fromMinutiToOreString(incarico.flessibilita)
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = spazio + spazio + '999'
        newObjSer.NumOreMovmn = getOreString(fless);
        newObjSer.NumMinMovmn = getMinutiString(fless);
        let obj = {riga: "", codice: ""}
        obj.riga =  newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        obj.codice = newObjSer.CodGiusvRP.replace(/\s/g, '');
        obj.riga = aggiungiFiller(obj.riga)
        array.push(obj)
    }
    return array
}

const generaRigheFileGPGB = (incarico, file, newObjDip, newObjSer) => {
    if(incarico.effettivo == 'RR'){
        newObjSer.VFGioRip = 'S'
        newObjSer.CodGiusvRP = '\xa0\xa0\xa0\xa0\xa0'
        newObjSer.NumOreMovmn = '00';
        newObjSer.NumMinMovmn = '00';
        file = file + newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
    }else if(incarico.disponibilita == 'RP' && incarico.effettivo == 'AS'){
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = '\xa0\xa0\xa0AS'
        newObjSer.NumOreMovmn = getOreString(incarico.oreStraordinarie);
        newObjSer.NumMinMovmn = getMinutiString(incarico.oreStraordinarie);;
        file = file + newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
    }else if(incarico.disponibilita == 'FG' && incarico.effettivo == 'FG'){
        newObjSer.VFGioRip = 'N'
        newObjSer.CodGiusvRP = '\xa0\xa0\xa0FG'
        newObjSer.NumOreMovmn = '00';
        newObjSer.NumMinMovmn = '00';
        file = file + newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
    }else{
        if(incarico.oreOrdinarie.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = '\xa0\xa0\xa001'
            newObjSer.NumOreMovmn = getOreString(incarico.oreOrdinarie);
            newObjSer.NumMinMovmn = getMinutiString(incarico.oreOrdinarie);
            file = file + newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        }
        if(incarico.bancaOreRetrib.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = '\xa0\xa0159'
            newObjSer.NumOreMovmn = getOreString(incarico.bancaOreRetrib);
            newObjSer.NumMinMovmn = getMinutiString(incarico.bancaOreRetrib);
            file = file + newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        }
        if(incarico.oreStraordinarie.length > 0){
            newObjSer.VFGioRip = 'N'
            newObjSer.CodGiusvRP = '\xa0\xa0351'
            newObjSer.NumOreMovmn = getOreString(incarico.oreStraordinarie);
            newObjSer.NumMinMovmn = getMinutiString(incarico.oreStraordinarie);
            file = file + newObjSer.codTipoRec + newObjSer.CodGiusvRP + newObjSer.CodGiusvPA + newObjSer.DataMovmn + newObjSer.NumOreMovmn + newObjSer.NumMinMovmn + newObjSer.NumCentMovmn + newObjSer.VFGioRip + newObjSer.VFGioChisStrrr + newObjSer.CodTurno;
        }
    }
    return file
}



export const generaFileTRRIPA = (incarichiDipendenteSalvati, user) => {
    let fileGenerale = ""
    incarichiDipendenteSalvati.map(el => {
        let file
        let arrayFile = []
        let newObjDip = {
            codTipoRec: '00',
            CodDipnRP: spazio20 + spazio,
            CodSoggDipn: spazio5 + spazio + spazio + spazio,
            numRapp: '000',
            VFGenrAutmTeor: 'N',
            XFill1: spazio10 + spazio10 + spazio10 + spazio10 + spazio10 + spazio10 + spazio5
        }
        let anno;
        let mese;
        newObjDip.CodDipnRP = newObjDip.CodDipnRP + el.dipendente.matricola
        newObjDip.CodDipnRP = newObjDip.CodDipnRP.slice(el.dipendente.matricola.length);
        let rigaDipendente = newObjDip.codTipoRec + newObjDip.CodDipnRP + newObjDip.CodSoggDipn + newObjDip.numRapp  + newObjDip.VFGenrAutmTeor;
        file = aggiungiFiller(rigaDipendente)
            el.incarichi.map(incarico => {
                if(checkIfMultiploOrLastMultiplo(incarico)){ 
                    let newObjSer = {
                        codTipoRec: '01',
                        CodGiusvRP: '',
                        CodGiusvPA: spazio + spazio,
                        DataMovmn:'',
                        NumOreMovmn: '',
                        NumMinMovmn: '',
                        NumCentMovmn: '00',
                        VFGioRip: 'N',
                        VFGioChisStrrr: spazio,
                        CodTurno: '0'
                    }
                    anno = incarico.data.slice(6)
                    mese = incarico.data.slice(3,5)
                    let giorno = incarico.data.slice(0,2)
                    newObjSer.DataMovmn = anno+mese+giorno
                    if(el.dipendente.qualifica == 'gpg'){
                        if(user.filiale.tipologiaFatturazione == "A"){
                            arrayFile = arrayFile.concat(generaRigaTurniArrayFileGPGB(incarico, newObjSer, el.dipendente))
                        }
                        if(user.filiale.tipologiaFatturazione == "B"){
                            arrayFile = arrayFile.concat(generaRigaTurniArrayFileGPGB(incarico, newObjSer, el.dipendente))
                        }
                    }
                    if(el.dipendente.qualifica == 'ots'){
                        let calcoloStrDivisoOTS = el.calcoloStrDivisoOTS == true ? true : false
                        if(user.filiale.tipologiaFatturazione == "A"){
                            arrayFile = arrayFile.concat(generaRigaTurniArrayFileOTSB(incarico, newObjSer, el.dipendente, calcoloStrDivisoOTS))
                        }
                        if(user.filiale.tipologiaFatturazione == "B"){
                            arrayFile = arrayFile.concat(generaRigaTurniArrayFileOTSB(incarico, newObjSer, el.dipendente, calcoloStrDivisoOTS))
                        }
                    } 
            }
        })
        
        arrayFile = ordinaFileTRRIPA(arrayFile)
        arrayFile.map(el => {
            if(el.riga.length > 0){
                file = file + el.riga
            }
        })
        let indennitaArray = el.indennita
        //per sostituire da voce 204 a 211 solo per filiale 'user'
        if(user.filiale._id == '619b689744deac1834895120'){
            let index = indennitaArray.findIndex(ind => ind.codice == '204');
            if(index > -1){
                indennitaArray[index].codice ='211'
            }
        }
        let arrayFileIndennita = generaRigaIndennitaArrayFile(indennitaArray, anno, mese)
        arrayFileIndennita.map(el => {
            if(el.riga.length > 0){
                file = file + el.riga
            }
        })
        
        if(el.dipendente.matricola == "013" && true){
            //checkIsEqualTRRIPA(file, fileprovaJSON)
            //console.log(el.dipendente.nome + " " + el.dipendente.cognome)
            //console.log(file)
        }
        fileGenerale = fileGenerale + file
    })
    stampaTRRIPA(fileGenerale)
}

const formattaData = (data) => {
    let newData = ""
    if(data){
        newData = data.substr(6, 4)+"-"+data.substr(3, 2)+"-"+data.substr(0, 2);
    }
    return newData
}


const getTipoVoceIndennita = (modalita) => {
    if(modalita == 'importo'){
        return "I"
    }
    if(modalita == 'ore'){
        return "H"
    }
    if(modalita == 'giorni'){
        return "G"
    }
    if(modalita == 'mesi'){
        return "M"
    }else{
        return ""
    }
}

export const generaFileNEWTRRIPA = ({incarichi, indennita, user, dipendente}) => {
    let oreFerOrdinarie = dipendente.ferOrd.replace('.', ':');

    let fileGenerale = `<?xml  version="1.0"  encoding="utf-8" ?>`
    let parteFornituraStart = "<Fornitura>";
    let parteFornituraEnd = "</Fornitura>";
    //let parteDipendenteStart = `<Dipendente CodAziendaUfficiale="${"000004"}" CodDipendenteUfficiale="${dipendente.matricola ? dipendente.matricola : ""}" CodDipendenteRilPres="${dipendente.matricola ? dipendente.matricola : ""}">`;
    let parteDipendenteStart = `<Dipendente CodAziendaUfficiale="" CodDipendenteRilPres="${dipendente.matricola ? dipendente.matricola : ""}">`;
    let parteDipendenteEnd = "</Dipendente>";
    let parteMovimentiStart = `<Movimenti GenerazioneAutomaticaDaTeorico="N">`;
    let parteMovimentiEnd = "</Movimenti>"
    incarichi.map(el => {
        if(el.effettivo != 'DI'){
            if(checkIfMultiploOrLastMultiplo(el)){ 
                if(el.effettivo == "AS"){
                if(el.oreOrdinarie.length > 0){
                    parteMovimentiStart = parteMovimentiStart + "<Movimento>";
                    parteMovimentiStart = parteMovimentiStart + "<CodGiustificativoRilPres>ORD</CodGiustificativoRilPres>";
                    parteMovimentiStart = parteMovimentiStart + "<CodGiustificativoUfficiale>01</CodGiustificativoUfficiale>";
                    parteMovimentiStart = parteMovimentiStart + `<Data>${formattaData(el.data)}</Data>`;    
                    parteMovimentiStart = parteMovimentiStart + `<NumOre>${getOreString(el.oreOrdinarie)}</NumOre>`;
                    parteMovimentiStart = parteMovimentiStart + `<NumMinuti>${getMinutiString(el.oreOrdinarie)}</NumMinuti>`
                    parteMovimentiStart = parteMovimentiStart + "<GiornoDiRiposo>N</GiornoDiRiposo>";
                    parteMovimentiStart = parteMovimentiStart + "<GiornoChiusuraStraordinari>N</GiornoChiusuraStraordinari>";
                    parteMovimentiStart = parteMovimentiStart + "</Movimento>";        
                }
                if(el.oreStraordinarie.length > 0){
                    parteMovimentiStart = parteMovimentiStart + "<Movimento>";
                    parteMovimentiStart = parteMovimentiStart + "<CodGiustificativoRilPres>351</CodGiustificativoRilPres>";
                    parteMovimentiStart = parteMovimentiStart + `<Data>${formattaData(el.data)}</Data>`;    
                    parteMovimentiStart = parteMovimentiStart + `<NumOre>${getOreString(el.oreStraordinarie)}</NumOre>`;
                    parteMovimentiStart = parteMovimentiStart + `<NumMinuti>${getMinutiString(el.oreStraordinarie)}</NumMinuti>`
                    parteMovimentiStart = parteMovimentiStart + "<GiornoDiRiposo>N</GiornoDiRiposo>";
                    parteMovimentiStart = parteMovimentiStart + "<GiornoChiusuraStraordinari>N</GiornoChiusuraStraordinari>";
                    parteMovimentiStart = parteMovimentiStart + "</Movimento>";        
                }
                if(el.bancaOreRetrib.length > 0){
                    parteMovimentiStart = parteMovimentiStart + "<Movimento>";
                    parteMovimentiStart = parteMovimentiStart + "<CodGiustificativoRilPres>159</CodGiustificativoRilPres>";
                    parteMovimentiStart = parteMovimentiStart + `<Data>${formattaData(el.data)}</Data>`;    
                    parteMovimentiStart = parteMovimentiStart + `<NumOre>${getOreString(el.bancaOreRetrib)}</NumOre>`;
                    parteMovimentiStart = parteMovimentiStart + `<NumMinuti>${getMinutiString(el.bancaOreRetrib)}</NumMinuti>`
                    parteMovimentiStart = parteMovimentiStart + "<GiornoDiRiposo>N</GiornoDiRiposo>";
                    parteMovimentiStart = parteMovimentiStart + "<GiornoChiusuraStraordinari>N</GiornoChiusuraStraordinari>";
                    parteMovimentiStart = parteMovimentiStart + "</Movimento>";        
                }
                if(el.bancaOreDaFruire.length > 0){
                    parteMovimentiStart = parteMovimentiStart + "<Movimento>";
                    parteMovimentiStart = parteMovimentiStart + "<CodGiustificativoRilPres>160</CodGiustificativoRilPres>";
                    parteMovimentiStart = parteMovimentiStart + `<Data>${formattaData(el.data)}</Data>`;    
                    parteMovimentiStart = parteMovimentiStart + `<NumOre>${getOreString(el.bancaOreDaFruire)}</NumOre>`;
                    parteMovimentiStart = parteMovimentiStart + `<NumMinuti>${getMinutiString(el.bancaOreDaFruire)}</NumMinuti>`
                    parteMovimentiStart = parteMovimentiStart + "<GiornoDiRiposo>N</GiornoDiRiposo>";
                    parteMovimentiStart = parteMovimentiStart + "<GiornoChiusuraStraordinari>N</GiornoChiusuraStraordinari>";
                    parteMovimentiStart = parteMovimentiStart + "</Movimento>";        
                }
                if(el.indennitaDomenicale.length > 0){
                    parteMovimentiStart = parteMovimentiStart + "<Movimento>";
                    parteMovimentiStart = parteMovimentiStart + "<CodGiustificativoRilPres>061</CodGiustificativoRilPres>";
                    parteMovimentiStart = parteMovimentiStart + `<Data>${formattaData(el.data)}</Data>`;    
                    parteMovimentiStart = parteMovimentiStart + `<NumOre>${getOreString(el.indennitaDomenicale)}</NumOre>`;
                    parteMovimentiStart = parteMovimentiStart + `<NumMinuti>${getMinutiString(el.indennitaDomenicale)}</NumMinuti>`
                    parteMovimentiStart = parteMovimentiStart + "<GiornoDiRiposo>N</GiornoDiRiposo>";
                    parteMovimentiStart = parteMovimentiStart + "<GiornoChiusuraStraordinari>N</GiornoChiusuraStraordinari>";
                    parteMovimentiStart = parteMovimentiStart + "</Movimento>";        
                }
                if(el.oreStraordinarieFestive.length > 0){
                    parteMovimentiStart = parteMovimentiStart + "<Movimento>";
                    parteMovimentiStart = parteMovimentiStart + "<CodGiustificativoRilPres>053</CodGiustificativoRilPres>";
                    parteMovimentiStart = parteMovimentiStart + `<Data>${formattaData(el.data)}</Data>`;    
                    parteMovimentiStart = parteMovimentiStart + `<NumOre>${getOreString(el.oreStraordinarieFestive)}</NumOre>`;
                    parteMovimentiStart = parteMovimentiStart + `<NumMinuti>${getMinutiString(el.oreStraordinarieFestive)}</NumMinuti>`
                    parteMovimentiStart = parteMovimentiStart + "<GiornoDiRiposo>N</GiornoDiRiposo>";
                    parteMovimentiStart = parteMovimentiStart + "<GiornoChiusuraStraordinari>N</GiornoChiusuraStraordinari>";
                    parteMovimentiStart = parteMovimentiStart + "</Movimento>";        
                }   
                }
                if(el.effettivo == "RR"){
                    parteMovimentiStart = parteMovimentiStart + "<Movimento>";
                    parteMovimentiStart = parteMovimentiStart + "<CodGiustificativoRilPres>00112</CodGiustificativoRilPres>";
                    parteMovimentiStart = parteMovimentiStart + `<Data>${formattaData(el.data)}</Data>`;    
                    parteMovimentiStart = parteMovimentiStart + `<NumOre>${getOreString(oreFerOrdinarie)}</NumOre>`;
                    parteMovimentiStart = parteMovimentiStart + `<NumMinuti>0</NumMinuti>`
                    parteMovimentiStart = parteMovimentiStart + "<GiornoDiRiposo>N</GiornoDiRiposo>";
                    parteMovimentiStart = parteMovimentiStart + "<GiornoChiusuraStraordinari>N</GiornoChiusuraStraordinari>";
                    parteMovimentiStart = parteMovimentiStart + "</Movimento>";    
                }
            }
        } 
    })
    let parteIndennitaStart = `<VociRetributive>`;
    let parteIndennitaEnd = `</VociRetributive>`;
    indennita.map(el => {
        if(el.oreGiorni > 0){
            parteIndennitaStart = parteIndennitaStart + "<Voce>";
            parteIndennitaStart = parteIndennitaStart + `<CodVoceRilPres>${"00"+el.codice}</CodVoceRilPres>`;
            parteIndennitaStart = parteIndennitaStart + `<CodTipoCedolino>50</CodTipoCedolino>`;
            parteIndennitaStart = parteIndennitaStart + `<CodTipoVoce>${getTipoVoceIndennita(el.modalita)}</CodTipoVoce>`;
            parteIndennitaStart = parteIndennitaStart + `<Quantita>${el.oreGiorni}</Quantita>`;
            parteIndennitaStart = parteIndennitaStart + "</Voce>";
        } 
    })


    fileGenerale = fileGenerale + parteFornituraStart + parteDipendenteStart + parteMovimentiStart + parteMovimentiEnd + parteIndennitaStart + parteIndennitaEnd + parteDipendenteEnd + parteFornituraEnd
   stampaNEWTRRIPA(fileGenerale)
}

