import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import {Helmet} from "react-helmet";
import Moment from 'react-moment'
import { useReactToPrint } from 'react-to-print';
import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import raggruppamentiJSON from "../../../utils/raggruppamenti.json";
import QuadratureElement from './QuadratureElement';
import QuadratureElementTotali from './QuadratureElementTotali';
import QuadratureElementRapporti from './QuadratureElementRapporti';
import QuadratureElementVuoto from './QuadratureElementVuoto';
import Spinner from '../../layout/Spinner';
import { getQuadraturaOre } from '../../../actions/richiesta';
import { getFestivita } from '../../../actions/impostazione';
import { getFiliali } from '../../../actions/filiale';
const QuadratureOre = ({ auth: { user },richiesta: { quadraturaOre }, getQuadraturaOre, impostazione: { festivita }, getFestivita, filiale: { filiali }, getFiliali }) => {

    const [dataInizioInput, setDataInizioInput] = useState((setHours(setMinutes(startOfMonth(new Date()), 0), 0)));
    const [dataFineInput, setDataFineInput] = useState((setHours(setMinutes(endOfMonth(new Date()), 59), 23)));

    const [quadraturaOreDataA, setQuadraturaOreDataA] = useState([]);
    const [quadraturaOreDataB, setQuadraturaOreDataB] = useState([]);
    const [quadraturaOreDataE, setQuadraturaOreDataE] = useState([]);
    const [quadraturaOreDataD, setQuadraturaOreDataD] = useState([]);
    const [quadraturaOreDataC, setQuadraturaOreDataC] = useState([]);
    const [totaleOreQuadratureA, setTotaleOreQuadratureA] = useState([]);
    const [totaleOreQuadratureB, setTotaleOreQuadratureB] = useState([]);
    const [totaleOreQuadratureE, setTotaleOreQuadratureE] = useState([]);
    const [totaleOreQuadratureD, setTotaleOreQuadratureD] = useState([]);
    const [totaleOreQuadratureC, setTotaleOreQuadratureC] = useState([]);
    const [totaleOreQuadratureOreFatturate, setTotaleOreQuadratureOreFatturate] = useState([]);
    const [totaleOreQuadratureOreZone, setTotaleOreQuadratureOreZone] = useState([]);
    const [totaleOreQuadratureOreNonFatturate, setTotaleOreQuadratureOreNonFatturate] = useState([]);
    const [totaleOreQuadratureTot, setTotaleOreQuadratureTot] = useState([]);
    const [totaleOreQuadratureOrdinarie, setTotaleOreQuadratureOrdinarie] = useState([]);
    const [totaleOreQuadratureNonLavorate, setTotaleOreQuadratureNonLavorate] = useState([]);
    const [totaleOreQuadratureEE, setTotaleOreQuadratureEE] = useState([]);
    const [totaleOreBancaOreRetribuita, setTotaleOreBancaOreRetribuita] = useState([]);
    const [totaleOreBancaOreDaFruire, setTotaleOreBancaOreDaFruire] = useState([]);
    const [totaleOreStraordinarie, setTotaleOreStraordinarie] = useState([]);
    const [totaleOreStraordinarieFestive, setTotaleOreStraordinarieFestive] = useState([]);
    const [totaleOreSupplementariFestive, setTotaleOreSupplementariFestive] = useState([]);
    const [totaleOreStraordinarieRiposoSpostato, setTotaleOreStraordinarieRiposoSpostato] = useState([]);
    const [totaleFlessibilita, setTotaleFlessibilita] = useState([]);
    const [totaleOreSomministrati, setTotaleOreSomministrati] = useState([]);
    const [totaleOreQuadratureF, setTotaleOreQuadratureF] = useState([]);
    const [totaleOreQuadratureG, setTotaleOreQuadratureG] = useState([]);
    const [totaleOreQuadratureDMenoG, setTotaleOreQuadratureDMenoG] = useState([]);
    const [totaleOreQuadratureRapportoBG, setTotaleOreQuadratureRapportoBG] = useState([]);
    const [totaleOreQuadratureRapportoFE, setTotaleOreQuadratureRapportoFE] = useState([]);
    const [totaleOreQuadratureRapportoFG, setTotaleOreQuadratureRapportoFG] = useState([]);
    const [filialiList, setFilialiList] = useState([]);
    const [filtraFiliale, setFiltraFiliale] = useState(null);
    const [contatoreNonArchiviate, setContatoreNonArchiviate] = useState(0);
    const [contatorePrestatiNonArchiviate, setContatorePrestatiNonArchiviate] = useState(0);
    
    const [csvData, setCsvData] = useState([]);
    

    const headers = 
    [
        { label: 'descrizione', key: 'descrizione' },
        { label: 'gennaio', key: 'gennaio' },
        { label: 'febbraio', key: 'febbraio' },
        { label: 'marzo', key: 'marzo' },
        { label: 'aprile', key: 'aprile' },
        { label: 'maggio', key: 'maggio' },
        { label: 'giugno', key: 'giugno' },
        { label: 'luglio', key: 'luglio' },
        { label: 'agosto', key: 'agosto' },
        { label: 'settembre', key: 'settembre' },
        { label: 'ottobre', key: 'ottobre' },
        { label: 'novembre', key: 'novembre' },
        { label: 'dicembre', key: 'dicembre' },
        { label: 'totale', key: 'totale' }
    ];


    const [loadingRichieste, setLoadingRichieste] = useState(false);
    const [nomeFiliale, setNomeFiliale] = useState("");

    const getNomeMese = (mese) => {
        switch (mese) {
            case 0:
                return "gennaio"
            case 1:
                return "febbraio"
            case 2:
                return "marzo"
            case 3:
                return "aprile"
            case 4:
                return "maggio"
            case 5:
                return "giugno"
            case 6:
                return "luglio"
            case 7:
                return "agosto"
            case 8:
                return "settembre"
            case 9:
                return "ottobre"
            case 10:
                return "novembre"
            case 11:
                return "dicembre"
            default:
                return "gennaio"
        }

    }

    

    useEffect(() => {
        if (user && user.role == "organizzazione") {
            getFiliali();
        }
    }, [user]);

    useEffect(() => {
        if (user && user.role == "organizzazione"){
            if (filiali.length > 0) {
                let array = [];
                filiali.map(filiale => {
                    const index = user.vistaFiliali.findIndex(el => el._id == filiale._id);
                    if(index >= 0){
                        array.push(filiale)
                        setFiltraFiliale(filiale._id)
                        setNomeFiliale(filiale.nome)
                    }
                })
                setFilialiList(array)
            }
        }else{
            if(user && user.filiale && user.filiale.nome){
                setNomeFiliale(user.filiale.nome)
            } 
        }
        
    }, [filiali]);

    useEffect(() => {
        getFestivita()
    }, []);
   
    const dividiPerMese = (incarichi, tipologia) => {
        let array = []
        incarichi.map(incarico => {
            let mese = new Date(incarico.dataInizio).getMonth()
                let nomeMese = getNomeMese(mese)
                let index;
                if(tipologia === 'raggruppamento'){
                    if(incarico.puntoVendita.idRaggruppa == 74){
                        console.log(incarico)
                }
                    index = array.findIndex(el => { return el.idRaggruppa == incarico.puntoVendita.idRaggruppa })
                }
                if(tipologia === 'filiali'){
                    index = array.findIndex(el => { return el.idFiliale == incarico.filiale._id })
                }

                if(tipologia === 'somministrati'){
                    index = array.findIndex(el => { return el.idFiliale == "0" })
                }

                let durataString = getOreBetweenDates(incarico.oraInizio, incarico.oraFine)
                let durataMinutiInt = fromStringToMinuti(durataString)
                let oreOrdinarieContratto = fromStringToMinuti(incarico.dipendente.ferOrd.replace(".", ":"))
                let oreOrdinarieLavorate = getOreOrdinarielavorate(oreOrdinarieContratto, durataMinutiInt)
                let oreNonLavorate = getOreNonlavorate(oreOrdinarieContratto, durataMinutiInt)
                let oreStraordinarieLavorate = durataMinutiInt - oreOrdinarieLavorate
                oreStraordinarieLavorate = oreStraordinarieLavorate > 0 ? oreStraordinarieLavorate : 0
                let isFestivo = checkIfDataIsFestivo(new Date(incarico.dataInizio), festivita)

                if (index > -1) {
                    let obj = array[index]
                    obj[nomeMese].totale = obj[nomeMese].totale + durataMinutiInt
                    obj[nomeMese].ordinarie = obj[nomeMese].ordinarie + oreOrdinarieLavorate
                    obj[nomeMese].nonLavorate = obj[nomeMese].nonLavorate + oreNonLavorate

                    obj.totale.totale = obj.totale.totale + durataMinutiInt
                    obj.totale.ordinarie = obj.totale.ordinarie + oreOrdinarieLavorate
                    obj.totale.nonLavorate = obj.totale.nonLavorate + oreNonLavorate
                    if(isFestivo){
                        obj[nomeMese].straordinarieFestivi = obj[nomeMese].straordinarieFestivi + oreStraordinarieLavorate 
                        obj.totale.straordinarieFestivi = obj.totale.straordinarieFestivi + oreStraordinarieLavorate
                    }else{
                        if(incarico.dipendente.qualifica == 'gpg'){
                            let bancaOre = oreStraordinarieLavorate - 60
                            if(bancaOre > 0){
                                if (!incarico.dipendente.percBancaOre || incarico.dipendente.percBancaOre == "0") {
                                    obj[nomeMese].straordinarie = obj[nomeMese].straordinarie + 60
                                    obj[nomeMese].bancaOreRetribuita = obj[nomeMese].bancaOreRetribuita + bancaOre
                                    obj.totale.straordinarie = obj.totale.straordinarie + 60
                                    obj.totale.bancaOreRetribuita = obj.totale.bancaOreRetribuita + bancaOre
                                } else if (incarico.dipendente.percBancaOre == "50") {
                                    let meta = bancaOre / 2
                                    obj[nomeMese].straordinarie = obj[nomeMese].straordinarie + 60
                                    obj[nomeMese].bancaOreRetribuita = obj[nomeMese].bancaOreRetribuita + meta
                                    obj[nomeMese].bancaOreDaFruire = obj[nomeMese].bancaOreDaFruire + meta
                                    obj.totale.straordinarie = obj.totale.straordinarie + 60
                                    obj.totale.bancaOreRetribuita = obj.totale.bancaOreRetribuita + meta
                                    obj.totale.bancaOreDaFruire = obj.totale.bancaOreDaFruire + meta
                                } else if (incarico.percBancaOre == "100") {
                                    obj[nomeMese].straordinarie = obj[nomeMese].straordinarie + 60
                                    obj[nomeMese].bancaOreDaFruire = obj[nomeMese].bancaOreDaFruire + bancaOre
                                    obj.totale.straordinarie = obj.totale.straordinarie + 60
                                    obj.totale.bancaOreDaFruire = obj.totale.bancaOreDaFruire + bancaOre
                                }
                            }else{
                                obj[nomeMese].straordinarie = obj[nomeMese].straordinarie + oreStraordinarieLavorate 
                                obj.totale.straordinarie = obj.totale.straordinarie + oreStraordinarieLavorate
                            }
                        }else{
                            obj[nomeMese].straordinarie = obj[nomeMese].straordinarie + oreStraordinarieLavorate
                            obj.totale.straordinarie = obj.totale.straordinarie + oreStraordinarieLavorate
                        }
                    }
                    
    
                    array[index] = obj
                } else {
                    let newLine = { 
                        idFiliale: "", 
                        descrizione: "", 
                        gennaio: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0}, 
                        febbraio: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0}, 
                        marzo: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0}, 
                        aprile: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0}, 
                        maggio: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0}, 
                        giugno: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0}, 
                        luglio: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0}, 
                        agosto: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0}, 
                        settembre: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0}, 
                        ottobre: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0}, 
                        novembre: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0}, 
                        dicembre: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0}, 
                        totale: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0} 
                    }
                    newLine[nomeMese].totale = durataMinutiInt
                    newLine[nomeMese].ordinarie = newLine[nomeMese].ordinarie + oreOrdinarieLavorate
                    newLine[nomeMese].nonLavorate = newLine[nomeMese].nonLavorate + oreNonLavorate

                    newLine.totale.totale = durataMinutiInt
                    newLine.totale.ordinarie = oreOrdinarieLavorate
                    newLine.totale.nonLavorate = oreNonLavorate

                    if(isFestivo){
                        newLine[nomeMese].straordinarieFestivi = newLine[nomeMese].straordinarieFestivi + oreStraordinarieLavorate 
                        newLine.totale.straordinarieFestivi = newLine.totale.straordinarieFestivi + oreStraordinarieLavorate

                    }else{
                        if(incarico.dipendente.qualifica == 'gpg'){
                            let bancaOre = oreStraordinarieLavorate - 60
                            if(bancaOre > 0){
                                if (!incarico.dipendente.percBancaOre || incarico.dipendente.percBancaOre == "0") {
                                    newLine[nomeMese].straordinarie = newLine[nomeMese].straordinarie + 60
                                    newLine[nomeMese].bancaOreRetribuita = newLine[nomeMese].bancaOreRetribuita + bancaOre
                                    newLine.totale.straordinarie = newLine.totale.straordinarie + 60
                                    newLine.totale.bancaOreRetribuita = newLine.totale.bancaOreRetribuita + bancaOre
                                } else if (incarico.dipendente.percBancaOre == "50") {
                                    let meta = bancaOre / 2
                                    newLine[nomeMese].straordinarie = newLine[nomeMese].straordinarie + 60
                                    newLine[nomeMese].bancaOreRetribuita = newLine[nomeMese].bancaOreRetribuita + meta
                                    newLine[nomeMese].bancaOreDaFruire = newLine[nomeMese].bancaOreDaFruire + meta
                                    newLine.totale.straordinarie = newLine.totale.straordinarie + 60
                                    newLine.totale.bancaOreRetribuita = newLine.totale.bancaOreRetribuita + meta
                                    newLine.totale.bancaOreDaFruire = newLine.totale.bancaOreDaFruire + meta
                                } else if (incarico.percBancaOre == "100") {
                                    newLine[nomeMese].straordinarie = newLine[nomeMese].straordinarie + 60
                                    newLine[nomeMese].bancaOreDaFruire = newLine[nomeMese].bancaOreDaFruire + bancaOre
                                    newLine.totale.bancaOreDaFruire = newLine.totale.bancaOreDaFruire + bancaOre
                                }
                            }else{
                                newLine[nomeMese].straordinarie = newLine[nomeMese].straordinarie + oreStraordinarieLavorate
                                newLine.totale.straordinarie = newLine.totale.straordinarie + oreStraordinarieLavorate
                            }
                        }else{
                            newLine[nomeMese].straordinarie = newLine[nomeMese].straordinarie + oreStraordinarieLavorate
                            newLine.totale.straordinarie = oreStraordinarieLavorate
                        }
                    }
                    

                    if(tipologia === 'raggruppamento'){
                        newLine.idRaggruppa = incarico.puntoVendita.idRaggruppa
                        raggruppamentiJSON.raggruppamenti.map((a) => {
                            if (a.id == newLine.idRaggruppa) {
                                newLine.descrizione = a.nota
                            }
                        })
                    }
                    if(tipologia === 'filiali'){
                        newLine.idFiliale = incarico.filiale._id
                        newLine.descrizione = "PRESTITO A " + incarico.filiale.nome
                    }
                    if(tipologia === 'somministrati'){
                        newLine.idFiliale = "0"
                        newLine.descrizione = "SOMMA ORE SOMMINISTRATI"
                    }
                    array.push(newLine)
                }
        })
        return array
    }

    const dividiPerMeseOreSalvati = ({incarichi}) => {
        let newLine = { 
            idFiliale: "", 
            descrizione: "", 
            gennaio: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, straordinariRiposoSpostato: 0, supplementariFestive: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0, flessibilita: 0}, 
            febbraio: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, straordinariRiposoSpostato: 0, supplementariFestive: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0, flessibilita: 0}, 
            marzo: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, straordinariRiposoSpostato: 0, supplementariFestive: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0, flessibilita: 0}, 
            aprile: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, straordinariRiposoSpostato: 0, supplementariFestive: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0, flessibilita: 0}, 
            maggio: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, straordinariRiposoSpostato: 0, supplementariFestive: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0, flessibilita: 0}, 
            giugno: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, straordinariRiposoSpostato: 0, supplementariFestive: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0, flessibilita: 0}, 
            luglio: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, straordinariRiposoSpostato: 0, supplementariFestive: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0, flessibilita: 0}, 
            agosto: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, straordinariRiposoSpostato: 0, supplementariFestive: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0, flessibilita: 0}, 
            settembre: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, straordinariRiposoSpostato: 0, supplementariFestive: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0, flessibilita: 0}, 
            ottobre: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, straordinariRiposoSpostato: 0, supplementariFestive: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0, flessibilita: 0}, 
            novembre: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, straordinariRiposoSpostato: 0, supplementariFestive: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0, flessibilita: 0}, 
            dicembre: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, straordinariRiposoSpostato: 0, supplementariFestive: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0, flessibilita: 0}, 
            totale: {totale: 0, ordinarie: 0, nonLavorate: 0, straordinarie: 0, straordinarieFestivi: 0, straordinariRiposoSpostato: 0, supplementariFestive: 0, bancaOreRetribuita: 0, bancaOreDaFruire: 0, flessibilita: 0}
        }
        incarichi.map(incarico => {
            var dateParts = incarico.data.split("-");
            var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            let mese = new Date(dateObject).getMonth()
            let nomeMese = getNomeMese(mese)

            let oreOrdinarie = fromStringToMinuti(incarico.oreOrdinarie)
            let oreNonLavorate = fromStringToMinuti(incarico.oreNonLavorate)
            let bancaOreRetrib = fromStringToMinuti(incarico.bancaOreRetrib)
            
            let bancaOreDaFruire = fromStringToMinuti(incarico.bancaOreDaFruire)
            let oreStraordinarie = fromStringToMinuti(incarico.oreStraordinarie)
            let oreStraordinarieNotteOTS = fromStringToMinuti(incarico.oreStraordinarieNotteOTS)
            let oreStraordinarieTotali = oreStraordinarie + oreStraordinarieNotteOTS
            let oreStraordinarieFestive = fromStringToMinuti(incarico.oreStraordinarieFestive)
            let oreStraordinarieFestiveOTSGiorno = fromStringToMinuti(incarico.oreStraordinarieFestiveOTSGiorno)
            let oreStraordinarieFestiveOTSNotte = fromStringToMinuti(incarico.oreStraordinarieFestiveOTSNotte)
            let oreFestiveTotali = oreStraordinarieFestive + oreStraordinarieFestiveOTSGiorno + oreStraordinarieFestiveOTSNotte
            let oreStrRipSp = fromStringToMinuti(incarico.oreStrRipSp)
            let oreSupPartTime = fromStringToMinuti(incarico.oreSupPartTime)
            let oreFlessibilita = incarico.flessibilita



            newLine[nomeMese].ordinarie = newLine[nomeMese].ordinarie + oreOrdinarie
            newLine[nomeMese].nonLavorate = newLine[nomeMese].nonLavorate + oreNonLavorate
            newLine[nomeMese].straordinarie = newLine[nomeMese].straordinarie + oreStraordinarieTotali
            newLine[nomeMese].straordinarieFestivi = newLine[nomeMese].straordinarieFestivi + oreFestiveTotali
            newLine[nomeMese].straordinariRiposoSpostato = newLine[nomeMese].straordinariRiposoSpostato + oreStrRipSp
            newLine[nomeMese].supplementariFestive = newLine[nomeMese].supplementariFestive + oreSupPartTime
            newLine[nomeMese].bancaOreRetribuita = newLine[nomeMese].bancaOreRetribuita + bancaOreRetrib
            newLine[nomeMese].bancaOreDaFruire = newLine[nomeMese].bancaOreDaFruire + bancaOreDaFruire
            newLine[nomeMese].flessibilita = newLine[nomeMese].flessibilita + oreFlessibilita


            newLine.totale.ordinarie = newLine.totale.ordinarie + oreOrdinarie
            newLine.totale.nonLavorate = newLine.totale.nonLavorate + oreNonLavorate
            newLine.totale.straordinarie = newLine.totale.straordinarie + oreStraordinarieTotali
            newLine.totale.straordinarieFestivi = newLine.totale.straordinarieFestivi + oreFestiveTotali
            newLine.totale.straordinariRiposoSpostato = newLine.totale.straordinariRiposoSpostato + oreStrRipSp
            newLine.totale.supplementariFestive = newLine.totale.supplementariFestive + oreSupPartTime
            newLine.totale.bancaOreRetribuita = newLine.totale.bancaOreRetribuita + bancaOreRetrib
            newLine.totale.bancaOreDaFruire = newLine.totale.bancaOreDaFruire + bancaOreDaFruire
            newLine.totale.flessibilita = newLine.totale.flessibilita + oreFlessibilita
        })
        return newLine
    }

    const checkPrestatoAltraFiliale = (incarico) => {
        if(user.role == 'organizzazione'){
            if(incarico.prestato){
                if(incarico.dipendente.filiale._id == filtraFiliale){
                    return true
                }else{
                    return false
                }
            }else{
                return false
            }
        }else{
            if(incarico.prestato){
                if(incarico.dipendente.filiale._id == user.filiale._id){
                    return true
                }else{
                    return false
                }
            }else{
                return false
            }
        } 
    }
   
    useEffect(() => {
        let arrayGruppoA = [];
        let arrayGruppoB = [];
        let arrayGruppoC = [];
        let arrayGruppoD = [];
        let arrayGruppoE = [];
        let arrayGruppoSomministrati = [];
        let oreSalvate = {};
        let arrayCSV = []

        console.log(quadraturaOre)
        if(quadraturaOre && quadraturaOre.dati){
            let cont= 0
            quadraturaOre.dati.map((el, i) => {
                if(el.prestato){
                    if(el.dipendente.filiale._id == "634d6703aad1111a4c048797"){
                        console.log(i)
                        console.log(el)
                        let durataString = getOreBetweenDates(el.oraInizio, el.oraFine)
                        let durataMinutiInt = fromStringToMinuti(durataString)
                        cont = cont + durataMinutiInt
                    }  
                }
            }) 
            console.log(cont)
        }
        

        let arrayTurniSalvati = [];
        quadraturaOre.incarichiSalvati.map(el => {
            el.incarichi.map(ele => {
                arrayTurniSalvati.push(ele)
            })
        })

        if(quadraturaOre && quadraturaOre.richiesteNonArchiviate && quadraturaOre.richiestePrestatiNonArchiviate){
            console.log(quadraturaOre.richiestePrestatiNonArchiviate)
            setContatoreNonArchiviate(quadraturaOre.richiesteNonArchiviate.length)
            setContatorePrestatiNonArchiviate(quadraturaOre.richiestePrestatiNonArchiviate.length)
        }
        

        oreSalvate = dividiPerMeseOreSalvati({incarichi: arrayTurniSalvati})

        quadraturaOre.richieste.map(a => {
            if(checkPrestatoAltraFiliale(a)){
                arrayGruppoE.push(a)
            }else{
                let index = raggruppamentiJSON.raggruppamenti.findIndex(b => {return a.puntoVendita.idRaggruppa == b.id })
                if(index > -1){
                    if(raggruppamentiJSON.raggruppamenti[index].raggruppa === 0){
                        arrayGruppoA.push(a)
                    }
                    if(raggruppamentiJSON.raggruppamenti[index].raggruppa === 1){
                        arrayGruppoB.push(a)
                    }
                    if(raggruppamentiJSON.raggruppamenti[index].raggruppa === 3){
                        arrayGruppoD.push(a)
                    }
                    if(raggruppamentiJSON.raggruppamenti[index].raggruppa === 4){
                        arrayGruppoC.push(a)
                    }
                }
            }
            if(a.dipendente.somministrato){
                arrayGruppoSomministrati.push(a)
            }
        })
        arrayGruppoA = dividiPerMese(arrayGruppoA, 'raggruppamento')
        setQuadraturaOreDataA(arrayGruppoA)
        arrayGruppoA.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))
        let totQuadA = calcolaTotali(arrayGruppoA)
        setTotaleOreQuadratureA(totQuadA)
        arrayCSV.push({descrizione: "TOTALE ORE A", gennaio: totQuadA.gennaio, febbraio: totQuadA.febbraio, marzo: totQuadA.marzo, aprile: totQuadA.aprile, maggio: totQuadA.maggio, giugno: totQuadA.giugno, luglio: totQuadA.luglio, agosto: totQuadA.agosto, settembre: totQuadA.settembre, ottobre: totQuadA.ottobre, novembre: totQuadA.novembre, dicembre: totQuadA.dicembre, totale: totQuadA.totale})

        arrayGruppoB = dividiPerMese(arrayGruppoB, 'raggruppamento')
        setQuadraturaOreDataB(arrayGruppoB)
        arrayGruppoB.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))
        let totQuadB = calcolaTotali(arrayGruppoB)
        setTotaleOreQuadratureB(totQuadB)
        arrayCSV.push({descrizione: "TOTALE ORE B", gennaio: totQuadB.gennaio, febbraio: totQuadB.febbraio, marzo: totQuadB.marzo, aprile: totQuadB.aprile, maggio: totQuadB.maggio, giugno: totQuadB.giugno, luglio: totQuadB.luglio, agosto: totQuadB.agosto, settembre: totQuadB.settembre, ottobre: totQuadB.ottobre, novembre: totQuadB.novembre, dicembre: totQuadB.dicembre, totale: totQuadB.totale})

        arrayGruppoE = dividiPerMese(arrayGruppoE, 'filiali')
        setQuadraturaOreDataE(arrayGruppoE)
        arrayGruppoE.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))
        let totQuadE = calcolaTotali(arrayGruppoE)
        setTotaleOreQuadratureE(totQuadE)
        arrayCSV.push({descrizione: "TOTALE ORE E", gennaio: totQuadE.gennaio, febbraio: totQuadE.febbraio, marzo: totQuadE.marzo, aprile: totQuadE.aprile, maggio: totQuadE.maggio, giugno: totQuadE.giugno, luglio: totQuadE.luglio, agosto: totQuadE.agosto, settembre: totQuadE.settembre, ottobre: totQuadE.ottobre, novembre: totQuadE.novembre, dicembre: totQuadE.dicembre, totale: totQuadE.totale})

        arrayGruppoD = dividiPerMese(arrayGruppoD, 'raggruppamento')
        setQuadraturaOreDataD(arrayGruppoD)
        arrayGruppoD.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))
        let totQuadD = calcolaTotali(arrayGruppoD)
        setTotaleOreQuadratureD(totQuadD)
        arrayCSV.push({descrizione: "TOTALE ORE D", gennaio: totQuadD.gennaio, febbraio: totQuadD.febbraio, marzo: totQuadD.marzo, aprile: totQuadD.aprile, maggio: totQuadD.maggio, giugno: totQuadD.giugno, luglio: totQuadD.luglio, agosto: totQuadD.agosto, settembre: totQuadD.settembre, ottobre: totQuadD.ottobre, novembre: totQuadD.novembre, dicembre: totQuadD.dicembre, totale: totQuadD.totale})

        arrayGruppoC = dividiPerMese(arrayGruppoC, 'raggruppamento')
        setQuadraturaOreDataC(arrayGruppoC)
        arrayGruppoC.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))
        let totQuadC = calcolaTotali(arrayGruppoC)
        setTotaleOreQuadratureC(totQuadC)
        arrayCSV.push({descrizione: "TOTALE ORE C", gennaio: totQuadC.gennaio, febbraio: totQuadC.febbraio, marzo: totQuadC.marzo, aprile: totQuadC.aprile, maggio: totQuadC.maggio, giugno: totQuadC.giugno, luglio: totQuadC.luglio, agosto: totQuadC.agosto, settembre: totQuadC.settembre, ottobre: totQuadC.ottobre, novembre: totQuadC.novembre, dicembre: totQuadC.dicembre, totale: totQuadC.totale})

        arrayGruppoSomministrati = dividiPerMese(arrayGruppoSomministrati, 'somministrati')
        //arrayGruppoSomministrati.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))
        let totQuadSomministrati = calcolaTotali(arrayGruppoSomministrati)
        setTotaleOreSomministrati(totQuadSomministrati)
        arrayCSV.push({descrizione: "SOMMA ORE SOMMINISTRATI", gennaio: totQuadSomministrati.gennaio, febbraio: totQuadSomministrati.febbraio, marzo: totQuadSomministrati.marzo, aprile: totQuadSomministrati.aprile, maggio: totQuadSomministrati.maggio, giugno: totQuadSomministrati.giugno, luglio: totQuadSomministrati.luglio, agosto: totQuadSomministrati.agosto, settembre: totQuadSomministrati.settembre, ottobre: totQuadSomministrati.ottobre, novembre: totQuadSomministrati.novembre, dicembre: totQuadSomministrati.dicembre, totale: totQuadSomministrati.totale})

        setTotaleOreQuadratureOreFatturate(totQuadA)
        arrayCSV.push({descrizione: "TOTALE ORE FATTURATE (A)", gennaio: totQuadA.gennaio, febbraio: totQuadA.febbraio, marzo: totQuadA.marzo, aprile: totQuadA.aprile, maggio: totQuadA.maggio, giugno: totQuadA.giugno, luglio: totQuadA.luglio, agosto: totQuadA.agosto, settembre: totQuadA.settembre, ottobre: totQuadA.ottobre, novembre: totQuadA.novembre, dicembre: totQuadA.dicembre, totale: totQuadA.totale})
        setTotaleOreQuadratureOreZone(totQuadB)
        arrayCSV.push({descrizione: "TOTALE ORE ZONE", gennaio: totQuadB.gennaio, febbraio: totQuadB.febbraio, marzo: totQuadB.marzo, aprile: totQuadB.aprile, maggio: totQuadB.maggio, giugno: totQuadB.giugno, luglio: totQuadB.luglio, agosto: totQuadB.agosto, settembre: totQuadB.settembre, ottobre: totQuadB.ottobre, novembre: totQuadB.novembre, dicembre: totQuadB.dicembre, totale: totQuadB.totale})

        let totB = calcolaTotaliTra2Array(totQuadE, totQuadD)
        setTotaleOreQuadratureOreNonFatturate(totB)
        arrayCSV.push({descrizione: "TOTALE ORE NON FATTURATE (B)", gennaio: totB.gennaio, febbraio: totB.febbraio, marzo: totB.marzo, aprile: totB.aprile, maggio: totB.maggio, giugno: totB.giugno, luglio: totB.luglio, agosto: totB.agosto, settembre: totB.settembre, ottobre: totB.ottobre, novembre: totB.novembre, dicembre: totB.dicembre, totale: totB.totale})

        let totD = calcolaTotaliTra4Array(totQuadA, totQuadB, totQuadE, totQuadD)
        setTotaleOreQuadratureTot(totD)
        arrayCSV.push({descrizione: "TOTALE ORE (D)", gennaio: totD.gennaio, febbraio: totD.febbraio, marzo: totD.marzo, aprile: totD.aprile, maggio: totD.maggio, giugno: totD.giugno, luglio: totD.luglio, agosto: totD.agosto, settembre: totD.settembre, ottobre: totD.ottobre, novembre: totD.novembre, dicembre: totD.dicembre, totale: totD.totale})


        
        //
        let totOrdinarie = calcolaTotaliOreSalvate({incarichi: oreSalvate, campo: 'ordinarie'})
        totOrdinarie.descrizione = 'RETRIBUZIONE ORDINARIA'
        setTotaleOreQuadratureOrdinarie([totOrdinarie])
        let arrayTotOrdinarie = [totOrdinarie]
        arrayTotOrdinarie.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))

        let totNonLavorate = calcolaTotaliOreSalvate({incarichi: oreSalvate, campo: 'nonLavorate'})
        totNonLavorate.descrizione = 'ORE NON LAVORATE'
        setTotaleOreQuadratureNonLavorate([totNonLavorate])
        let arrayTotNonLavorate = [totNonLavorate]
        arrayTotNonLavorate.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))

        let totE = calcolaDifferenzaTotali(totOrdinarie, totNonLavorate)
        setTotaleOreQuadratureEE(totE)
        arrayCSV.push({descrizione: "TOTALE ORE (E)", gennaio: totE.gennaio, febbraio: totE.febbraio, marzo: totE.marzo, aprile: totE.aprile, maggio: totE.maggio, giugno: totE.giugno, luglio: totE.luglio, agosto: totE.agosto, settembre: totE.settembre, ottobre: totE.ottobre, novembre: totE.novembre, dicembre: totE.dicembre, totale: totE.totale})


        let totBancaRetrib = calcolaTotaliOreSalvate({incarichi: oreSalvate, campo: 'bancaOreRetribuita'})
        totBancaRetrib.descrizione = 'BANCA ORE RETRIBUITE'
        setTotaleOreBancaOreRetribuita([totBancaRetrib])
        let arrayTotBancaRetrib = [totBancaRetrib]
        arrayTotBancaRetrib.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))

        
        let bancaOreDaFruire = calcolaTotaliOreSalvate({incarichi: oreSalvate, campo: 'bancaOreDaFruire'})
        bancaOreDaFruire.descrizione = 'BANCA ORE DA FRUIRE'
        setTotaleOreBancaOreDaFruire([bancaOreDaFruire])
        let arrayTotBancaOreDaFruire = [bancaOreDaFruire]
        arrayTotBancaOreDaFruire.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))


        let oreStraordinarie = calcolaTotaliOreSalvate({incarichi: oreSalvate, campo: 'straordinarie'})
        oreStraordinarie.descrizione = 'ORE STRAORDINARIE'
        setTotaleOreStraordinarie([oreStraordinarie])
        let arrayTotOreStraordinarie = [oreStraordinarie]
        arrayTotOreStraordinarie.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))


        let oreSupplementariFestive = calcolaTotaliOreSalvate({incarichi: oreSalvate, campo: 'supplementariFestive'})
        oreSupplementariFestive.descrizione = 'ORE SUPPLEMENTARI FESTIVE'
        setTotaleOreSupplementariFestive([oreSupplementariFestive])
        let arrayTotOreSupplementariFestive = [oreSupplementariFestive]
        arrayTotOreSupplementariFestive.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))

        
        let oreStraordinarieFestive = calcolaTotaliOreSalvate({incarichi: oreSalvate, campo: 'straordinarieFestivi'})
        oreStraordinarieFestive.descrizione = 'ORE STRAORDONARIE FESTIVE'
        setTotaleOreStraordinarieFestive([oreStraordinarieFestive])
        let arrayTotOreStraordinarieFestive = [oreStraordinarieFestive]
        arrayTotOreStraordinarieFestive.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))

        let oreStraordinarieRiposoSpostato = calcolaTotaliOreSalvate({incarichi: oreSalvate, campo: 'straordinariRiposoSpostato'})
        oreStraordinarieRiposoSpostato.descrizione = 'ORE STRAORDINARIE RIPOSO SPOSTATO'
        setTotaleOreStraordinarieRiposoSpostato([oreStraordinarieRiposoSpostato])
        let arrayTotOreStraordinarieRiposoSpostato = [oreStraordinarieRiposoSpostato]
        arrayTotOreStraordinarieRiposoSpostato.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))

        let oreFlessibilita = calcolaTotaliOreSalvate({incarichi: oreSalvate, campo: 'flessibilita'})
        oreFlessibilita.descrizione = 'SOMMA ORE FLESSIBILITA'
        setTotaleFlessibilita([oreFlessibilita])
        let arrayTotOreFlessibilita = [oreFlessibilita]
        arrayTotOreFlessibilita.map(el => arrayCSV.push({descrizione: el.descrizione, gennaio: el.gennaio.totale, febbraio: el.febbraio.totale, marzo: el.marzo.totale, aprile: el.aprile.totale, maggio: el.maggio.totale, giugno: el.giugno.totale, luglio: el.luglio.totale, agosto: el.agosto.totale, settembre: el.settembre.totale, ottobre: el.ottobre.totale, novembre: el.novembre.totale, dicembre: el.dicembre.totale, totale: el.totale.totale}))

        let totF = calcolaTotaliF(totBancaRetrib, bancaOreDaFruire, oreStraordinarie, oreStraordinarieFestive, oreSupplementariFestive, oreStraordinarieRiposoSpostato)
        setTotaleOreQuadratureF(totF)
        arrayCSV.push({descrizione: "TOTALE (F)", gennaio: totF.gennaio, febbraio: totF.febbraio, marzo: totF.marzo, aprile: totF.aprile, maggio: totF.maggio, giugno: totF.giugno, luglio: totF.luglio, agosto: totF.agosto, settembre: totF.settembre, ottobre: totF.ottobre, novembre: totF.novembre, dicembre: totF.dicembre, totale: totF.totale})

        let totG = calcolaTotaliTra2Array(totE, totF)
        setTotaleOreQuadratureG(totG)
        arrayCSV.push({descrizione: "TOTALE GENERALE (G)", gennaio: totG.gennaio, febbraio: totG.febbraio, marzo: totG.marzo, aprile: totG.aprile, maggio: totG.maggio, giugno: totG.giugno, luglio: totG.luglio, agosto: totG.agosto, settembre: totG.settembre, ottobre: totG.ottobre, novembre: totG.novembre, dicembre: totG.dicembre, totale: totG.totale})

        let diffDG = calcolaDifferenzaDMenoG(totD, totG)
        setTotaleOreQuadratureDMenoG(diffDG)
        arrayCSV.push({descrizione: "DIFFERENZA (D-G)", gennaio: diffDG.gennaio, febbraio: diffDG.febbraio, marzo: diffDG.marzo, aprile: diffDG.aprile, maggio: diffDG.maggio, giugno: diffDG.giugno, luglio: diffDG.luglio, agosto: diffDG.agosto, settembre: diffDG.settembre, ottobre: diffDG.ottobre, novembre: diffDG.novembre, dicembre: diffDG.dicembre, totale: diffDG.totale})

        let rapportoBG = calcolaRapporto2Array(totB, totG)
        setTotaleOreQuadratureRapportoBG(rapportoBG)
        arrayCSV.push({descrizione: "RAPPORTO (B/G)", gennaio: rapportoBG.gennaio, febbraio: rapportoBG.febbraio, marzo: rapportoBG.marzo, aprile: rapportoBG.aprile, maggio: rapportoBG.maggio, giugno: rapportoBG.giugno, luglio: rapportoBG.luglio, agosto: rapportoBG.agosto, settembre: rapportoBG.settembre, ottobre: rapportoBG.ottobre, novembre: rapportoBG.novembre, dicembre: rapportoBG.dicembre, totale: rapportoBG.totale})

        let rapportoFE = calcolaRapporto2Array(totF, totE)
        setTotaleOreQuadratureRapportoFE(rapportoFE)
        arrayCSV.push({descrizione: "RAPPORTO (F/E)", gennaio: rapportoFE.gennaio, febbraio: rapportoFE.febbraio, marzo: rapportoFE.marzo, aprile: rapportoFE.aprile, maggio: rapportoFE.maggio, giugno: rapportoFE.giugno, luglio: rapportoFE.luglio, agosto: rapportoFE.agosto, settembre: rapportoFE.settembre, ottobre: rapportoFE.ottobre, novembre: rapportoFE.novembre, dicembre: rapportoFE.dicembre, totale: rapportoFE.totale})

        let rapportoFG = calcolaRapporto2Array(totF, totG)
        setTotaleOreQuadratureRapportoFG(rapportoFG)
        arrayCSV.push({descrizione: "RAPPORTO (F/G)", gennaio: rapportoFG.gennaio, febbraio: rapportoFG.febbraio, marzo: rapportoFG.marzo, aprile: rapportoFG.aprile, maggio: rapportoFG.maggio, giugno: rapportoFG.giugno, luglio: rapportoFG.luglio, agosto: rapportoFG.agosto, settembre: rapportoFG.settembre, ottobre: rapportoFG.ottobre, novembre: rapportoFG.novembre, dicembre: rapportoFG.dicembre, totale: rapportoFG.totale})

        setCsvData(arrayCSV)

        setLoadingRichieste(false)
    }, [quadraturaOre]);

const calcolaTotaliOre = (arrayA, arrayB, arrayC, arrayD, campo) => {
    let obj = {gennaio: {totale: 0}, febbraio: {totale: 0}, marzo: {totale: 0}, aprile: {totale: 0}, maggio: {totale: 0}, giugno: {totale: 0}, luglio: {totale: 0}, agosto: {totale: 0}, settembre: {totale: 0}, ottobre: {totale: 0}, novembre: {totale: 0}, dicembre: {totale: 0}, totale: {totale: 0}}
    if(arrayA.length > 0 && arrayB.length > 0 && arrayC.length > 0 && arrayD.length > 0){
        obj.gennaio.totale = arrayA[0].gennaio[campo] + arrayB[0].gennaio[campo] + arrayC[0].gennaio[campo] + arrayD[0].gennaio[campo]
        obj.febbraio.totale = arrayA[0].febbraio[campo] + arrayB[0].febbraio[campo] + arrayC[0].febbraio[campo] + arrayD[0].febbraio[campo]
        obj.marzo.totale = arrayA[0].marzo[campo] + arrayB[0].marzo[campo] + arrayC[0].marzo[campo] + arrayD[0].marzo[campo]
        obj.aprile.totale = arrayA[0].aprile[campo] + arrayB[0].aprile[campo] + arrayC[0].aprile[campo] + arrayD[0].aprile[campo]
        obj.maggio.totale = arrayA[0].maggio[campo] + arrayB[0].maggio[campo] + arrayC[0].maggio[campo] + arrayD[0].maggio[campo]
        obj.giugno.totale = arrayA[0].giugno[campo] + arrayB[0].giugno[campo] + arrayC[0].giugno[campo] + arrayD[0].giugno[campo]
        obj.luglio.totale = arrayA[0].luglio[campo] + arrayB[0].luglio[campo] + arrayC[0].luglio[campo] + arrayD[0].luglio[campo]
        obj.agosto.totale = arrayA[0].agosto[campo] + arrayB[0].agosto[campo] + arrayC[0].agosto[campo] + arrayD[0].agosto[campo]
        obj.settembre.totale = arrayA[0].settembre[campo] + arrayB[0].settembre[campo] + arrayC[0].settembre[campo] + arrayD[0].settembre[campo]
        obj.ottobre.totale = arrayA[0].ottobre[campo] + arrayB[0].ottobre[campo] + arrayC[0].ottobre[campo] + arrayD[0].ottobre[campo]
        obj.novembre.totale = arrayA[0].novembre[campo] + arrayB[0].novembre[campo] + arrayC[0].novembre[campo] + arrayD[0].novembre[campo]
        obj.dicembre.totale = arrayA[0].dicembre[campo] + arrayB[0].dicembre[campo] + arrayC[0].dicembre[campo] + arrayD[0].dicembre[campo]
        obj.totale.totale = arrayA[0].totale[campo] + arrayB[0].totale[campo] + arrayC[0].totale[campo] + arrayD[0].totale[campo]
    }
    return obj
}

const calcolaTotaliOreSalvate = ({incarichi, campo}) => {
    let obj = {gennaio: {totale: 0}, febbraio: {totale: 0}, marzo: {totale: 0}, aprile: {totale: 0}, maggio: {totale: 0}, giugno: {totale: 0}, luglio: {totale: 0}, agosto: {totale: 0}, settembre: {totale: 0}, ottobre: {totale: 0}, novembre: {totale: 0}, dicembre: {totale: 0}, totale: {totale: 0}}
    obj.gennaio.totale = incarichi.gennaio[campo] + obj.gennaio.totale
    obj.febbraio.totale = incarichi.febbraio[campo] + obj.febbraio.totale
    obj.marzo.totale = incarichi.marzo[campo] + obj.marzo.totale
    obj.aprile.totale = incarichi.aprile[campo] + obj.aprile.totale
    obj.maggio.totale = incarichi.maggio[campo] + obj.maggio.totale
    obj.giugno.totale = incarichi.giugno[campo] + obj.giugno.totale
    obj.luglio.totale = incarichi.luglio[campo] + obj.luglio.totale
    obj.agosto.totale = incarichi.agosto[campo] + obj.agosto.totale
    obj.settembre.totale = incarichi.settembre[campo] + obj.settembre.totale
    obj.ottobre.totale = incarichi.ottobre[campo] + obj.ottobre.totale
    obj.novembre.totale = incarichi.novembre[campo] + obj.novembre.totale
    obj.dicembre.totale = incarichi.dicembre[campo] + obj.dicembre.totale
    obj.totale.totale = incarichi.totale[campo] + obj.totale.totale
    return obj
}

const calcolaTotali = (array) => {
    let obj = {gennaio: 0, febbraio: 0, marzo: 0, aprile: 0, maggio: 0, giugno: 0, luglio: 0, agosto: 0, settembre: 0, ottobre: 0, novembre: 0, dicembre: 0, totale: 0}
    obj.gennaio = totaleOreQuadratureMese(array, 'gennaio')
    obj.febbraio = totaleOreQuadratureMese(array, 'febbraio')
    obj.marzo = totaleOreQuadratureMese(array, 'marzo')
    obj.aprile = totaleOreQuadratureMese(array, 'aprile')
    obj.maggio = totaleOreQuadratureMese(array, 'maggio')
    obj.giugno = totaleOreQuadratureMese(array, 'giugno')
    obj.luglio = totaleOreQuadratureMese(array, 'luglio')
    obj.agosto = totaleOreQuadratureMese(array, 'agosto')
    obj.settembre = totaleOreQuadratureMese(array, 'settembre')
    obj.ottobre = totaleOreQuadratureMese(array, 'ottobre')
    obj.novembre = totaleOreQuadratureMese(array, 'novembre')
    obj.dicembre = totaleOreQuadratureMese(array, 'dicembre')
    obj.totale = totaleOreQuadratureMese(array, 'totale')
    return obj
}

const calcolaDifferenzaTotali = (oreOrdinarie, oreNonLavorate) => {
    let obj = {gennaio: 0, febbraio: 0, marzo: 0, aprile: 0, maggio: 0, giugno: 0, luglio: 0, agosto: 0, settembre: 0, ottobre: 0, novembre: 0, dicembre: 0, totale: 0}
    if(oreOrdinarie && oreNonLavorate){
        obj.gennaio = oreOrdinarie.gennaio.totale - oreNonLavorate.gennaio.totale
        obj.febbraio = oreOrdinarie.febbraio.totale - oreNonLavorate.febbraio.totale
        obj.marzo = oreOrdinarie.marzo.totale - oreNonLavorate.marzo.totale
        obj.aprile = oreOrdinarie.aprile.totale - oreNonLavorate.aprile.totale
        obj.maggio = oreOrdinarie.maggio.totale - oreNonLavorate.maggio.totale
        obj.giugno = oreOrdinarie.giugno.totale - oreNonLavorate.giugno.totale
        obj.luglio = oreOrdinarie.luglio.totale - oreNonLavorate.luglio.totale
        obj.agosto = oreOrdinarie.agosto.totale - oreNonLavorate.agosto.totale
        obj.settembre = oreOrdinarie.settembre.totale - oreNonLavorate.settembre.totale
        obj.ottobre = oreOrdinarie.ottobre.totale - oreNonLavorate.ottobre.totale
        obj.novembre = oreOrdinarie.novembre.totale - oreNonLavorate.novembre.totale
        obj.dicembre = oreOrdinarie.dicembre.totale - oreNonLavorate.dicembre.totale
        obj.totale = oreOrdinarie.totale.totale - oreNonLavorate.totale.totale
    }
    return obj
}

const calcolaDifferenzaDMenoG = (D, G) => {
    let obj = {gennaio: 0, febbraio: 0, marzo: 0, aprile: 0, maggio: 0, giugno: 0, luglio: 0, agosto: 0, settembre: 0, ottobre: 0, novembre: 0, dicembre: 0, totale: 0}
    if(D && G){
        obj.gennaio = D.gennaio - G.gennaio
        obj.febbraio = D.febbraio - G.febbraio
        obj.marzo = D.marzo - G.marzo
        obj.aprile = D.aprile - G.aprile
        obj.maggio = D.maggio - G.maggio
        obj.giugno = D.giugno - G.giugno
        obj.luglio = D.luglio - G.luglio
        obj.agosto = D.agosto - G.agosto
        obj.settembre = D.settembre - G.settembre
        obj.ottobre = D.ottobre - G.ottobre
        obj.novembre = D.novembre - G.novembre
        obj.dicembre = D.dicembre - G.dicembre
        obj.totale = D.totale - G.totale
    }
    return obj
}

const calcolaRapporto2Array = (arrayA, arrayB) => {
    let obj = {gennaio: 0, febbraio: 0, marzo: 0, aprile: 0, maggio: 0, giugno: 0, luglio: 0, agosto: 0, settembre: 0, ottobre: 0, novembre: 0, dicembre: 0, totale: 0}
    if(arrayA && arrayB){
        obj.gennaio = isFinite(arrayA.gennaio / arrayB.gennaio) ? (arrayA.gennaio / arrayB.gennaio).toFixed(2) : 0
        obj.febbraio = isFinite(arrayA.febbraio / arrayB.febbraio) ? (arrayA.febbraio / arrayB.febbraio).toFixed(2) : 0
        obj.marzo = isFinite(arrayA.marzo / arrayB.marzo) ? (arrayA.marzo / arrayB.marzo).toFixed(2) : 0
        obj.aprile = isFinite(arrayA.aprile / arrayB.aprile) ? (arrayA.aprile / arrayB.aprile).toFixed(2) : 0
        obj.maggio = isFinite(arrayA.maggio / arrayB.maggio) ? (arrayA.maggio / arrayB.maggio).toFixed(2) : 0
        obj.giugno = isFinite(arrayA.giugno / arrayB.giugno) ? (arrayA.giugno / arrayB.giugno).toFixed(2) : 0
        obj.luglio = isFinite(arrayA.luglio / arrayB.luglio) ? (arrayA.luglio / arrayB.luglio).toFixed(2) : 0
        obj.agosto = isFinite(arrayA.agosto / arrayB.agosto) ? (arrayA.agosto / arrayB.agosto).toFixed(2) : 0
        obj.settembre = isFinite(arrayA.settembre / arrayB.settembre) ? (arrayA.settembre / arrayB.settembre).toFixed(2) : 0
        obj.ottobre = isFinite(arrayA.ottobre / arrayB.ottobre) ? (arrayA.ottobre / arrayB.ottobre).toFixed(2) : 0
        obj.novembre = isFinite(arrayA.novembre / arrayB.novembre) ? (arrayA.novembre / arrayB.novembre).toFixed(2) : 0
        obj.dicembre = isFinite(arrayA.dicembre / arrayB.dicembre) ? (arrayA.dicembre / arrayB.dicembre).toFixed(2) : 0
        obj.totale = isFinite(arrayA.totale / arrayB.totale) ? (arrayA.totale / arrayB.totale).toFixed(2) : 0
    }
    return obj
}

const calcolaTotaliTra2Array = (arrayA, arrayB) => {
    let obj = {gennaio: 0, febbraio: 0, marzo: 0, aprile: 0, maggio: 0, giugno: 0, luglio: 0, agosto: 0, settembre: 0, ottobre: 0, novembre: 0, dicembre: 0, totale: 0}
    if(arrayA && arrayB){
        obj.gennaio = arrayA.gennaio + arrayB.gennaio
        obj.febbraio = arrayA.febbraio + arrayB.febbraio
        obj.marzo = arrayA.marzo + arrayB.marzo
        obj.aprile = arrayA.aprile + arrayB.aprile
        obj.maggio = arrayA.maggio + arrayB.maggio
        obj.giugno = arrayA.giugno + arrayB.giugno
        obj.luglio = arrayA.luglio + arrayB.luglio
        obj.agosto = arrayA.agosto + arrayB.agosto
        obj.settembre = arrayA.settembre + arrayB.settembre
        obj.ottobre = arrayA.ottobre + arrayB.ottobre
        obj.novembre = arrayA.novembre + arrayB.novembre
        obj.dicembre = arrayA.dicembre + arrayB.dicembre
        obj.totale = arrayA.totale + arrayB.totale
    }
    return obj
}

const calcolaTotaliTra4Array = (arrayA, arrayB, arrayC, arrayD) => {
    let obj = {gennaio: 0, febbraio: 0, marzo: 0, aprile: 0, maggio: 0, giugno: 0, luglio: 0, agosto: 0, settembre: 0, ottobre: 0, novembre: 0, dicembre: 0, totale: 0}
    if(arrayA && arrayB && arrayC && arrayD){
        obj.gennaio = arrayA.gennaio + arrayB.gennaio + arrayC.gennaio + arrayD.gennaio
        obj.febbraio = arrayA.febbraio + arrayB.febbraio + arrayC.febbraio + arrayD.febbraio
        obj.marzo = arrayA.marzo + arrayB.marzo + arrayC.marzo + arrayD.marzo
        obj.aprile = arrayA.aprile + arrayB.aprile + arrayC.aprile + arrayD.aprile
        obj.maggio = arrayA.maggio + arrayB.maggio + arrayC.maggio + arrayD.maggio
        obj.giugno = arrayA.giugno + arrayB.giugno + arrayC.giugno + arrayD.giugno
        obj.luglio = arrayA.luglio + arrayB.luglio + arrayC.luglio + arrayD.luglio
        obj.agosto = arrayA.agosto + arrayB.agosto + arrayC.agosto + arrayD.agosto
        obj.settembre = arrayA.settembre + arrayB.settembre + arrayC.settembre + arrayD.settembre
        obj.ottobre = arrayA.ottobre + arrayB.ottobre + arrayC.ottobre + arrayD.ottobre
        obj.novembre = arrayA.novembre + arrayB.novembre + arrayC.novembre + arrayD.novembre
        obj.dicembre = arrayA.dicembre + arrayB.dicembre + arrayC.dicembre + arrayD.dicembre
        obj.totale = arrayA.totale + arrayB.totale + arrayC.totale + arrayD.totale
    }
    return obj
}

const calcolaTotaliF = (arrayA, arrayB, arrayC, arrayD, arrayE, arrayF) => {
    let obj = {gennaio: 0, febbraio: 0, marzo: 0, aprile: 0, maggio: 0, giugno: 0, luglio: 0, agosto: 0, settembre: 0, ottobre: 0, novembre: 0, dicembre: 0, totale: 0}
    if(arrayA && arrayB && arrayC && arrayD){
        obj.gennaio = arrayA.gennaio.totale + arrayB.gennaio.totale + arrayC.gennaio.totale + arrayD.gennaio.totale + arrayE.gennaio.totale + arrayF.gennaio.totale
        obj.febbraio = arrayA.febbraio.totale + arrayB.febbraio.totale + arrayC.febbraio.totale + arrayD.febbraio.totale + arrayE.febbraio.totale + arrayF.febbraio.totale
        obj.marzo = arrayA.marzo.totale + arrayB.marzo.totale + arrayC.marzo.totale + arrayD.marzo.totale + arrayE.marzo.totale + arrayF.marzo.totale
        obj.aprile = arrayA.aprile.totale + arrayB.aprile.totale + arrayC.aprile.totale + arrayD.aprile.totale + arrayE.aprile.totale + arrayF.aprile.totale
        obj.maggio = arrayA.maggio.totale + arrayB.maggio.totale + arrayC.maggio.totale + arrayD.maggio.totale + arrayE.maggio.totale + arrayF.maggio.totale
        obj.giugno = arrayA.giugno.totale + arrayB.giugno.totale + arrayC.giugno.totale + arrayD.giugno.totale + arrayE.giugno.totale + arrayF.giugno.totale
        obj.luglio = arrayA.luglio.totale + arrayB.luglio.totale + arrayC.luglio.totale + arrayD.luglio.totale + arrayE.luglio.totale + arrayF.luglio.totale
        obj.agosto = arrayA.agosto.totale + arrayB.agosto.totale + arrayC.agosto.totale + arrayD.agosto.totale + arrayE.agosto.totale + arrayF.agosto.totale
        obj.settembre = arrayA.settembre.totale + arrayB.settembre.totale + arrayC.settembre.totale + arrayD.settembre.totale + arrayE.settembre.totale + arrayF.settembre.totale
        obj.ottobre = arrayA.ottobre.totale + arrayB.ottobre.totale + arrayC.ottobre.totale + arrayD.ottobre.totale + arrayE.ottobre.totale + arrayF.ottobre.totale
        obj.novembre = arrayA.novembre.totale + arrayB.novembre.totale + arrayC.novembre.totale + arrayD.novembre.totale + arrayE.novembre.totale + arrayF.novembre.totale
        obj.dicembre = arrayA.dicembre.totale + arrayB.dicembre.totale + arrayC.dicembre.totale + arrayD.dicembre.totale + arrayE.dicembre.totale + arrayF.dicembre.totale
        obj.totale = arrayA.totale.totale + arrayB.totale.totale + arrayC.totale.totale + arrayD.totale.totale + arrayE.totale.totale + arrayF.totale.totale
    }
    return obj
}
    
    const getOreInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseInt(split[0])
        } else {
            return 0
        }
    }

    const getMinutiInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseFloat(split[1])
        } else {
            return 0
        }
    }

    const fromStringToMinuti = (oreString) => {
        var minuti = getMinutiInt(oreString);
        var ore = getOreInt(oreString) * 60
        return ore + minuti
    }

    const fromMinutiToOreString = (minutiTotali) => {
        if (minutiTotali > 0) {
            var minuti = minutiTotali % 60;
            var ore = (minutiTotali - minuti) / 60;
            if (ore == 0 && minuti == 0) {
                return ""
            } else {
                return formattaOre(ore) + ":" + formattaMinuti(minuti)
            }
        } else {
            return ""
        }
    }
    
    const totaleOreQuadratureMese = (incarichi, mese) => {
        let somma = 0
        incarichi.map(el => {
            somma = somma + el[mese].totale
        })
        return somma
    }

    const getOreOrdinarielavorate = (oreOrdinarieContratto, oreLavorate) => {
        if(oreOrdinarieContratto > oreLavorate){
            return oreLavorate
        }else{
            return oreOrdinarieContratto
        }
    }

    const getOreNonlavorate = (oreOrdinarieContratto, oreLavorate) => {
        if(oreOrdinarieContratto > oreLavorate){
            return oreOrdinarieContratto - oreLavorate
        }else{
            return 0
        }
    }
    

    const getOreBetweenDates = (start, end) => {
        start = start.split(":");
        end = end.split(":");
        var startDate = new Date(0, 0, 0, start[0], start[1], 0);
        var endDate = new Date(0, 0, 0, end[0], end[1], 0);
        var diff = endDate.getTime() - startDate.getTime();
        var hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        var minutes = Math.floor(diff / 1000 / 60);
        if (hours < 0)
            hours = hours + 24;
        if (isNaN(hours) || isNaN(minutes)) {
            return ""
        }
        return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
    }

    const formattaOre = (ore) => {
        return (ore <= 9 ? "0" : "") + ore
    }

    const formattaMinuti = (minuti) => {
        return (minuti <= 9 ? "0" : "") + minuti;
    }

    const checkIfDataIsFestivo = (data, festivita) => {
        const day = ("0" + data.getDate()).slice(-2)
        const month = ("0" + (data.getMonth() + 1)).slice(-2);
        const year = data.getFullYear()
        const string = day+"-"+month+"-"+year
        const isFestivo = festivita.includes(string)
        return isFestivo
    }

    //FUNZIONI ONCLICK

    const onClickGeneraQuadratureOre = () => {
        setContatoreNonArchiviate(0)
        setContatorePrestatiNonArchiviate(0)
        getQuadraturaOre({ dataInizio: dataInizioInput, dataFine: dataFineInput, idFiliale: filtraFiliale })
        setLoadingRichieste(true)
    }
    //FUNZIONI ONCHANGE

    //*
    const onChangeRangeDateInizio = (data) => {
        setDataInizioInput(data)
        setDataFineInput(setHours(setMinutes(endOfMonth(data), 59), 23))
    }

    //*
    const onChangeRangeDateFine = (data) => {
        setDataFineInput(data)
    }

    const onChangeFiltraDipendentiPerFiliale = i => {
        setFiltraFiliale(filialiList[i]._id)
        setNomeFiliale(filialiList[i].nome)
    };


    const componentRef = useRef();
    const print = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "quadratura_ore"
    });


    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        const headerLabel = header.map(function (row) { return row.label })
        sheetData.unshift(headerLabel);
        return sheetData;
    }

    const trasformaTabellaExcelDaMinutiAOre = (array) => {
        array.map(el => {
            if(el.descrizione !== "RAPPORTO (B/G)" && el.descrizione !== "RAPPORTO (F/E)" && el.descrizione !== "RAPPORTO (F/G)"){
                el.gennaio = el.gennaio > 0 ? fromMinutiToOreString(el.gennaio) : el.gennaio
                el.febbraio = el.febbraio > 0 ? fromMinutiToOreString(el.febbraio) : el.febbraio
                el.marzo = el.marzo > 0 ? fromMinutiToOreString(el.marzo) : el.marzo
                el.aprile = el.aprile > 0 ? fromMinutiToOreString(el.aprile) : el.aprile
                el.maggio = el.maggio > 0 ? fromMinutiToOreString(el.maggio) : el.maggio
                el.giugno = el.giugno > 0 ? fromMinutiToOreString(el.giugno) : el.giugno
                el.luglio = el.luglio > 0 ? fromMinutiToOreString(el.luglio) : el.luglio
                el.agosto = el.agosto > 0 ? fromMinutiToOreString(el.agosto) : el.agosto
                el.settembre = el.settembre > 0 ? fromMinutiToOreString(el.settembre) : el.settembre
                el.ottobre = el.ottobre > 0 ? fromMinutiToOreString(el.ottobre) : el.ottobre
                el.novembre = el.novembre > 0 ? fromMinutiToOreString(el.novembre) : el.novembre
                el.dicembre = el.dicembre > 0 ? fromMinutiToOreString(el.dicembre) : el.dicembre
                el.totale = el.totale > 0 ? fromMinutiToOreString(el.totale) : el.totale
            }
        })
        return array
    }

    async function saveAsExcel(data, header) {
        console.log(data)
        let newData = trasformaTabellaExcelDaMinutiAOre(data)
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(newData, header);
            const totalColumns = sheetData[0].length;

            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "quadratura_ore.xlsx");
            });
        });
    }

    return (
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Quadratura ore - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <style type="text/css">
            {"@media print{@page {size: landscape; margin: 0.5cm}}"}
            </style>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_inner_100">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Quadrature ore</div>
                                <span className={`${contatoreNonArchiviate === 0 ? "green_label" : "red_label"} label_incarichi`}>{`non confermati: ${contatoreNonArchiviate}`}</span>
                                <span className={`${contatorePrestatiNonArchiviate === 0 ? "green_label" : "red_label"} label_incarichi`}>{`prestati non confermate: ${contatorePrestatiNonArchiviate}`}</span>
                            </div>
                            <div className="dati_container">
                                <div className="fun_quadrature_ore">
                                    <div className="row row_datapicker">
                                        <div className="item_1 filtro_wrap_incarichi">
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data inizio</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataInizioInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateInizio}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data fine</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataFineInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateFine}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={() => { onClickGeneraQuadratureOre() }} className="button w-button">Genera quadratura</a>
                                                    </form>
                                                </div>
                                            </div>
                                            {user && user.role == 'organizzazione' ?
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                            <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filiale</div>
                                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idCliente" onChange={e => onChangeFiltraDipendentiPerFiliale(e.target.value)} required>
                                                            {filialiList.map((elementoFiliale, i) => (
                                                                <option key={i} value={i} selected={elementoFiliale._id == filtraFiliale} >{elementoFiliale.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                            </div>
                                            : ''
                                            }
                                        </div>
                                    </div>
                                    <div ref={componentRef} className='print-landscape' id="page_quadratura">
                                    <div className="row">
                                    <h4>QUADRATURA ORE periodo <Moment format='DD/MM/YYYY'>{dataInizioInput}</Moment> - <Moment format='DD/MM/YYYY'>{dataFineInput}</Moment></h4>
                                    <h4>Filiale: {nomeFiliale}</h4>
                                    </div>
                                    <div ref={componentRef} className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_quadrature_ore intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Descrizione</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura"></div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Gennaio</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Febbraio</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Marzo</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Aprile</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Maggio</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Giugno</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Luglio</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Agosto</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Settembre</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Ottobre</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Novembre</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Dicembre</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table_quadratura">Totale</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {loadingRichieste ? <Spinner /> :
                                        <div className="body_table quadrature">
                                        <QuadratureElement 
                                            quadraturaOreData= {quadraturaOreDataA}
                                            bold={false}
                                        />
                                        <QuadratureElementTotali 
                                            labelA = {'TOTALE ORE A'}
                                            totaleOreQuadrature={totaleOreQuadratureA}
                                        />
                                        <QuadratureElementVuoto />
                                        <QuadratureElement 
                                            quadraturaOreData= {quadraturaOreDataB}
                                            bold={false}
                                        />
                                        <QuadratureElementTotali 
                                            labelA = {'TOTALE ORE B'}
                                            totaleOreQuadrature={totaleOreQuadratureB}
                                        />
                                        <QuadratureElementVuoto />
                                        <QuadratureElement 
                                            quadraturaOreData= {quadraturaOreDataC}
                                            bold={false}
                                        />
                                        <QuadratureElementTotali 
                                            labelA = {'TOTALE ORE C'}
                                            totaleOreQuadrature={totaleOreQuadratureC}
                                        />
                                        <QuadratureElementVuoto />
                                        <QuadratureElement 
                                            quadraturaOreData= {quadraturaOreDataD}
                                            bold={false}
                                        />
                                        <QuadratureElementTotali 
                                            labelA = {'TOTALE ORE D'}
                                            totaleOreQuadrature={totaleOreQuadratureD}
                                        />
                                        <QuadratureElementVuoto />
                                        <QuadratureElement 
                                            quadraturaOreData= {quadraturaOreDataE}
                                            bold={false}
                                        />
                                        <QuadratureElementTotali 
                                            labelA = {'TOTALE ORE E'}
                                            totaleOreQuadrature={totaleOreQuadratureE}
                                        />
                                        <QuadratureElementVuoto />
                                        <QuadratureElementTotali 
                                            labelA = {'TOTALE ORE FATTURATE'}
                                            labelB={'(A)'}
                                            totaleOreQuadrature={totaleOreQuadratureOreFatturate}
                                        />
                                        <QuadratureElementTotali 
                                            labelA = {'TOTALE ORE ZONE'}
                                            totaleOreQuadrature={totaleOreQuadratureOreZone}
                                        />
                                        <QuadratureElementTotali 
                                            labelA = {'TOTALE ORE NON FATTURATE'}
                                            labelB={'(B)'}
                                            totaleOreQuadrature={totaleOreQuadratureOreNonFatturate}
                                        />
                                        <QuadratureElementTotali 
                                            labelA = {'TOTALE ORE'}
                                            labelB={'(D)'}
                                            totaleOreQuadrature={totaleOreQuadratureTot}
                                        />
                                        <QuadratureElementVuoto />
                                        <QuadratureElement 
                                            quadraturaOreData={totaleOreQuadratureOrdinarie}
                                            bold={false}
                                        />
                                        <QuadratureElement 
                                            quadraturaOreData={totaleOreQuadratureNonLavorate}
                                            bold={false}
                                        />
                                        <QuadratureElementTotali 
                                            labelA = {'TOTALE'}
                                            labelB={'(E)'}
                                            totaleOreQuadrature={totaleOreQuadratureEE}
                                        />
                                        <QuadratureElementVuoto />
                                        <QuadratureElement 
                                            quadraturaOreData={totaleOreBancaOreRetribuita}
                                            bold={false}
                                        />
                                        <QuadratureElement 
                                            quadraturaOreData={totaleOreBancaOreDaFruire}
                                            bold={false}
                                        />
                                        <QuadratureElement 
                                            quadraturaOreData={totaleOreStraordinarie}
                                            bold={false}
                                        />
                                        <QuadratureElement 
                                            quadraturaOreData={totaleOreSupplementariFestive}
                                            bold={false}
                                        />
                                        <QuadratureElement 
                                            quadraturaOreData={totaleOreStraordinarieFestive}
                                            bold={false}
                                        />
                                        <QuadratureElement 
                                            quadraturaOreData={totaleOreStraordinarieRiposoSpostato}
                                            bold={false}
                                        />
                                        <QuadratureElementTotali 
                                            labelA = {'TOTALE'}
                                            labelB={'(F)'}
                                            totaleOreQuadrature={totaleOreQuadratureF}
                                        />
                                        <QuadratureElementVuoto />
                                        <QuadratureElementTotali 
                                            labelA = {'TOTALE GENERALE'}
                                            labelB={'(G)'}
                                            totaleOreQuadrature={totaleOreQuadratureG}
                                        />
                                        <QuadratureElementTotali 
                                            labelA = {'DIFFERENZA'}
                                            labelB={'(D-G)'}
                                            totaleOreQuadrature={totaleOreQuadratureDMenoG}
                                        />
                                        <QuadratureElementRapporti 
                                            labelA = {'RAPPORTO'}
                                            labelB={'(B/G)'}
                                            totaleOreQuadrature={totaleOreQuadratureRapportoBG}
                                        />
                                        <QuadratureElementRapporti 
                                            labelA = {'RAPPORTO'}
                                            labelB={'(F/E)'}
                                            totaleOreQuadrature={totaleOreQuadratureRapportoFE}
                                        />
                                        <QuadratureElementRapporti 
                                            labelA = {'RAPPORTO'}
                                            labelB={'(F/G)'}
                                            totaleOreQuadrature={totaleOreQuadratureRapportoFG}
                                        />
                                        <QuadratureElementVuoto />
                                        <QuadratureElement 
                                            quadraturaOreData={totaleFlessibilita}
                                            bold={true}
                                        />
                                        <QuadratureElementTotali 
                                            labelA = {'SOMMA ORE SOMMINISTRATI'}
                                            labelB={''}
                                            totaleOreQuadrature={totaleOreSomministrati}
                                        />
                                        </div>
                                        }
                                    </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button penultimo_largo">
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button`} onClick={(e) => csvData.length == 0 ? null : saveAsExcel(csvData, headers)}>Scarica Excel</a>
                                                </div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button`} onClick={(e) => csvData.length == 0 ? null : print()}>Stampa PDF</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

QuadratureOre.protoTypes = {
    getQuadraturaOre: PropTypes.func.isRequired,
    getFestivita: PropTypes.func.isRequired,
    getFiliali: PropTypes.func.isRequired,
    richiesta: PropTypes.object.isRequired,
    filiale: PropTypes.object.isRequired,
    impostazione: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    richiesta: state.richiesta,
    auth: state.auth,
    impostazione: state.impostazione,
    filiale: state.filiale
});

export default connect(mapStateToProps, { getQuadraturaOre, getFestivita, getFiliali })(QuadratureOre);