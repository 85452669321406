import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { CSVLink } from 'react-csv';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import Moment from 'react-moment'
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import Spinner from '../../layout/Spinner';
import disponibilitaJSON from "../../../utils/disponibilita.json"
import { getIncarichiDipendente, getDipendente } from '../../../actions/dipendente';
import { inviaTurniDipendenteViaEmail } from '../../../actions/invioTurni';
const IncarichiDipendente = ({ dipendente: { dipendente, incarichiDipendente, loading }, alert, getIncarichiDipendente, idDipendente, setOpenIncarichiDipendente, getDipendente, inviaTurniDipendenteViaEmail }) => {

    const [dataInizioInput, setDataInizioInput] = useState((setHours(setMinutes(startOfMonth(new Date()), 0), 0)));
    const [dataFineInput, setDataFineInput] = useState((setHours(setMinutes(endOfMonth(new Date()), 59), 23)));
    const [csvData, setCsvData] = useState([]);
    const [datiDipendente, setDatiDipendente] = useState({ nome: "", cognome: "" });
    const [incarichiLoading, setIncarichiLoading] = useState(true);
    const [arrayIncarichiDipendente, setArrayIncarichiDipendente] = useState([]); 
    const [totaleOreEseguite, setTotaleOreEseguite] = useState("00:00");
    const [checkBoxSoloPrestiti, setCheckBoxSoloPrestiti] = useState(false);



    const headers = [
        { label: 'Data', key: 'dataCSV' },
        { label: 'Disp.', key: 'disponibilita' },
        { label: 'Eff.', key: 'effettivo' },
        { label: 'Servizio', key: 'servizio' },
        { label: 'Dalle', key: 'oraInizio' },
        { label: 'Alle', key: 'oraFine' },
        { label: 'Ore fatte', key: 'ore' },
        { label: 'Nome', key: 'nome' },
        { label: 'Cognome', key: 'cognome' }
    ];





    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success") {
                //setDatiDipendente(dipendenteInitialState)
                //setOpenIncarichiDipendente(false);
            }
        }
    }, [alert]);

    useEffect(() => {
        return () => {

        }
    }, []);

    useEffect(() => {
        setIncarichiLoading(true)
        getIncarichiDipendente({ dataInizio: dataInizioInput, dataFine: dataFineInput, idDipendente: idDipendente });
    }, [getIncarichiDipendente, idDipendente, dataInizioInput, dataFineInput]);


    useEffect(() => {
        getDipendente(idDipendente);
    }, [getDipendente, idDipendente]);

    const fromStringToMinuti = (oreString) => {
        var minuti = getMinutiInt(oreString);
        var ore = getOreInt(oreString) * 60
        return ore + minuti
    }

    const getOreInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseInt(split[0])
        } else {
            return 0
        }
    }
    
    const getMinutiInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseFloat(split[1])
        } else {
            return 0
        }
    }

    useEffect(() => {
        let array = [];
        if (incarichiDipendente && incarichiDipendente.incarichi) {
            let sommaOreTurni = sommaOreturni(incarichiDipendente.incarichi, checkBoxSoloPrestiti)
            let sommaOreTurniString = sommaOreTurni ? fromMinutiToOreString(sommaOreTurni) : "00:00"
            setTotaleOreEseguite(sommaOreTurniString)
            incarichiDipendente.incarichi.map((el, i) => {
                if (el.effettivo == "AS") {
                    let newData = new Date(el.data)
                    let oldData = new Date(el.data)
                    if(el.riferitoGiornoDopo){
                        newData.setDate(newData.getDate() + 1);

                        //modifico data giorno dopo
                        if(el.disponibilita == 'DI'){
                            array.splice(i, 0, { dataCSV: toIsoString(oldData), data: oldData, disponibilita: el.disponibilita, effettivo: "DI", oraInizio: "", oraFine: "", nome: el.nome, cognome: el.cognome, servizio:  "DISPONIBILE", servizioEmail:  "DISPONIBILE", ore: "", localita: "", minutiInt: 0, indirizzo: "", prestato: false });
                        }
                    }
                    array.push({ dataCSV: toIsoString(newData), data: newData, disponibilita: el.disponibilita, effettivo: el.effettivo, oraInizio: el.oraInizio, oraFine: el.oraFine, nome: el.nome, cognome: el.cognome, servizio: el.servizio + " - " + el.localita, servizioEmail: el.servizio, ore: el.ore, localita: el.localita, minutiInt:  fromStringToMinuti(el.ore), indirizzo: el.puntoVendita.indirizzo, prestato: el.prestato})
                } else {
                    const index = disponibilitaJSON.disponibilita.findIndex(ele => ele.codice == el.effettivo);
                    let servizio = "";
                    if (index >= 0) {
                        servizio = disponibilitaJSON.disponibilita[index].nome;
                    }
                    array.push({ dataCSV: toIsoString(new Date(el.data)), data: el.data, disponibilita: el.disponibilita, effettivo: el.effettivo, servizio: servizio, servizioEmail: el.servizio, oraInizio: "", oraFine: "", ore: el.ore, nome: el.nome, cognome: el.cognome, localita: "", minutiInt: 0, indirizzo: "", prestato: false })
                }
            })
        }
        array = controllaGiorniLiberiErronei(array)
        array = cleanDipendentiGiorniEccezioneRR(array)
        //array.sort((a, b) => (a.dataCSV > b.dataCSV) ? 1 : -1)
        if(checkBoxSoloPrestiti){
            array = array.filter( el => { return  el.prestato == true; })
        }
        array.sort((a, b) => {
            let giornoA = a.dataCSV.slice(0, 2);
            let meseA = a.dataCSV.slice(3, 5);
            let annoA = a.dataCSV.slice(6, 10);
            let giornoB = b.dataCSV.slice(0, 2);
            let meseB = b.dataCSV.slice(3, 5);
            let annoB = b.dataCSV.slice(6, 10);
            if(annoA > annoB){
                return 1  
            }else if(annoA < annoB){
                return -1  
            }else{
                if(meseA > meseB){
                    return 1  
                }else if(meseA < meseB){
                    return -1  
                }else{
                    if(giornoA > giornoB){
                        return 1  
                    }else if(giornoA < giornoB){
                        return -1  
                    }else{
                        return -1
                    }
                }
            }
        })
        array.sort((a, b) => {
            if(a.dataCSV == b.dataCSV){
                let effettivoA = a.effettivo == "RP" || a.effettivo == "RR"
                let effettivoB = b.effettivo == "RP" || b.effettivo == "RR"
                if(effettivoA && !effettivoB){
                    return 1;
                }else if(!effettivoA && effettivoB){
                    return -1
                }else{
                    if(a.oraInizio > b.oraInizio){
                        return 1;
                    }else{
                        return -1
                    }
                }
            }
        })
        setArrayIncarichiDipendente(array)
        setCsvData(array)
        setIncarichiLoading(false)
    }, [incarichiDipendente, checkBoxSoloPrestiti]);


    const cleanDipendentiGiorniEccezioneRR = (giorni) => {
        let array = []
        giorni.map(el => {
            let index = array.findIndex(a => { return (a.dataCSV == el.dataCSV)})
            if(index === -1){
                array.push(el)
            }else{
                if(array[index].effettivo == "FE"){
                    if(el.effettivo != "FE"){
                        array[index] = el
                    }
                }else{
                    array.push(el)
                }
            }
        })
        return array
    }

    const controllaGiorniLiberiErronei = (incarichi) => {
        let array = []
        incarichi.map((el, j) => {
            if(el.disponibilita == "DI" && el.effettivo == "DI"){
                let index = incarichi.findIndex((a, i) => {
                    return (j !== i) && (a.dataCSV == el.dataCSV)
                })
                if(index >= 0){
                    //incarichi.splice(j, 1); 
                    
                }else{
                    array.push(el)
                }
            }else{
                array.push(el)
            }
        })
        return array
    }


    useEffect(() => {
        setDatiDipendente({
            nome: loading || !dipendente ? '' : dipendente.nome,
            cognome: loading || !dipendente ? '' : dipendente.cognome,
            email: loading || !dipendente ? '' : dipendente.email
        });
        setTotaleOreEseguite("00:00")
    }, [dipendente, loading]);


    const { nome, cognome, email } = datiDipendente;

    const onChangeSetDatiDipendente = e => setDatiDipendente({ ...datiDipendente, [e.target.name]: e.target.value });



    const onChangeRangeDateInizio = (data) => {
        setTotaleOreEseguite("00:00")
        setDataFineInput(setHours(setMinutes(endOfMonth(data), 59), 23))
        setDataInizioInput(data)
    }


    const onChangeRangeDateFine = (data) => {
        setDataFineInput(data)
    }

    const toIsoString = (date) => {
        const pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

        return pad(date.getDate()) +
            '-' + pad(date.getMonth() + 1) +
            '-' + date.getFullYear()
    }

    function filterFields(arr, fields) {
        return arr.map(obj => {
            let filteredObj = {};
            fields.forEach(field => {
                if (obj.hasOwnProperty(field)) {
                    filteredObj[field] = obj[field];
                }
            });
            return filteredObj;
        });
    }

    async function saveAsExcel(data, header) {
        console.log(data)
        const filteredData = filterFields(data, ['dataCSV', 'disponibilita', 'effettivo', 'servizio', 'oraInizio', 'oraFine', 'ore', 'nome', 'cognome']);

        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(filteredData, header);
            const totalColumns = sheetData[0].length;

            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "incarichi-dipendente.xlsx");
            });
        });
    }

    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        const headerLabel = header.map(function (row) { return row.label })
        sheetData.unshift(headerLabel);
        return sheetData;
    }

    const sommaOreturni = (turni, prestiti) => {
        let totaleOreLavorate = 0;
        turni.map(turno =>{
            if(prestiti){
                if(turno.prestato){
                    totaleOreLavorate = totaleOreLavorate + turno.minutiInt
                }
            }else{
                totaleOreLavorate = totaleOreLavorate + turno.minutiInt
            }
        })
        return totaleOreLavorate
    }

    const fromMinutiToOreString = (minutiTotali) => {
        if (minutiTotali > 0) {
            var minuti = minutiTotali % 60;
            var ore = (minutiTotali - minuti) / 60;
            if (ore == 0 && minuti == 0) {
                return ""
            } else {
                return formattaOre(ore) + ":" + formattaMinuti(minuti)
            }
        } else {
            return ""
        }
    }
    
    const formattaOre = (ore) => {
        return (ore <= 9 ? "0" : "") + ore
    }

    const formattaMinuti = (minuti) => {
        return (minuti <= 9 ? "0" : "") + minuti;
    }

    const onClickInviaTurniDipendenteViaEmail = () => {
        inviaTurniDipendenteViaEmail({email: email, turni: arrayIncarichiDipendente, idDipendente: idDipendente})
    }

    const onChangeSetSoloPrestati = e => {
        if(e.target.value == 'solo-prestiti'){
            setCheckBoxSoloPrestiti(true)
        }else{
            setCheckBoxSoloPrestiti(false)
        }
    }

    return (loading ? <Spinner /> :
        <div className="dati_container">
            <div className="fun_assegnazione_incarichi">
                <div className="row row_datapicker">
                    <div className="item_1 filtro_wrap_incarichi">
                        <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                            <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data inizio</div>
                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                <DatePicker
                                    value={dataInizioInput}
                                    format="dd/MM/y"
                                    weekPicker
                                    onChange={onChangeRangeDateInizio}
                                    locale="it-IT"
                                    clearIcon={null}
                                    weekStartDayIndex={1}
                                />
                            </div>
                        </div>
                        <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                            <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data fine</div>
                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                <DatePicker
                                    value={dataFineInput}
                                    format="dd/MM/y"
                                    weekPicker
                                    onChange={onChangeRangeDateFine}
                                    locale="it-IT"
                                    clearIcon={null}
                                    weekStartDayIndex={1}
                                />
                            </div>
                        </div>
                        <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                            <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Filtra prestiti</div>
                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                    <select className="form-control select" name="soloPrestiti" onChange={e => onChangeSetSoloPrestati(e)}>
                                    <option value={null} >Tutti</option>
                                    <option value={'solo-prestiti'} >Solo prestiti</option>
                                    </select>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="row">
                    <div className="item_2">
                        <div className="intestazione_table">
                            <div className="w-layout-grid div_column_incarichi_dipendente intestazione">
                                <div className="div_item_column">
                                    <div className="item_table">Data</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Disp.</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Eff.</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Servizio</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Dalle</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Alle</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Ore fatte</div>
                                </div>
                            </div>
                        </div>
                        {incarichiLoading ? <Spinner /> :
                            <div className="body_table">
                                {arrayIncarichiDipendente.map((el, i) => (
                                    <div key={"contratto" + i} className={`w-layout-grid div_column_incarichi_dipendente cursor ${el.stato == "archiviata" ? "green_label" : ''}`}>
                                        <div className="div_item_column">
                                            <div className="item_table"><Moment format='DD/MM/YYYY'>{el.data}</Moment></div>
                                        </div>
                                        <div className="div_item_column" >
                                            <div className="item_table">{el.disponibilita}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table">{el.effettivo}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table">{el.servizio}{el.localita ? " - " + el.localita : ""}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table">{el.oraInizio}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table">{el.oraFine}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table" >{el.ore}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                <div className="row">
                        <h4>N° di ore totale eseguite: {totaleOreEseguite}</h4>
                </div>
                <div className="row">
                    <div className="item_3 m_top_5">
                        <div className="list_button penultimo_largo">
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${csvData.length == 0 ? "button_disabled" : ""}`}>{csvData.length == 0 ? 'Scarica CSV' : <CSVLink className='button w-button' data={csvData} headers={headers} filename={`${cognome}-${nome}-${toIsoString(dataInizioInput)}.csv`} >Scarica CSV</CSVLink>}</a>
                            </div>
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${csvData.length == 0 ? "button_disabled" : ""}`} onClick={(e) => csvData.length == 0 ? null : saveAsExcel(csvData, headers)}>Scarica XLS</a>
                            </div>
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                            <input type="text" className="w-input select button_list" maxLength="256" name="email" data-name="email" value={email} placeholder="Inserisci email" id="email" onChange={e => onChangeSetDatiDipendente(e)} />
                            </div>
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${csvData.length == 0 ? "button_disabled" : ""}`} onClick={(e) => onClickInviaTurniDipendenteViaEmail()}>Invia email</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

IncarichiDipendente.protoTypes = {
    getIncarichiDipendente: PropTypes.func.isRequired,
    getDipendente: PropTypes.func.isRequired,
    inviaTurniDipendenteViaEmail: PropTypes.func.isRequired,
    dipendente: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    dipendente: state.dipendente,
    alert: state.alert
});

export default connect(mapStateToProps, { getIncarichiDipendente, getDipendente, inviaTurniDipendenteViaEmail })(IncarichiDipendente);